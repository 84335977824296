import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";

import classes from './Servizio.module.css';

export default function Calendario () {
    const rows = [], headers = [];
    const [festivoChecked, setFestivoChecked] = useState({
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false
    })

    useEffect(()=>{
        for (let i=0; i < 7; i++) { 
            setFestivoChecked((prevState) => {
                return {
                  ...prevState,
                  [i]: orariDb[i]?.festivo,
                };
              });
            
        }
    },[])

    const handleCheckboxChange = (i) => {
        setFestivoChecked((prevState) => {
          return {
            ...prevState,
            [i]: !prevState[i],
          };
        });
    };

    const response = useLoaderData();
    const orariDb = response.data.orari;
    
    const week = [
        "Lunedì",
        "Mertedì",
        "Mercoledì",
        "Giovedì",
        "Venerdì",
        "Sabato",
        "Domenica"
    ]

    const FrazioniGiorno = [
        "Apertura Mattina",
        "Chiusura Mattina",
        "Apertura Sera",
        "Chiusura Sera",
        "Giorno Festivo"
    ]

    function getPlaceholderValue(i, j, orariDb) {
        switch (i) {
            case 0:
                if (orariDb[j])
                    return orariDb[j].aperturaMattina;
                else
                    return 0;
            case 1:
                if (orariDb[j])
                    return orariDb[j].chiusuraMattina;
                else
                    return 0;
            case 2:
                if (orariDb[j])
                    return orariDb[j].aperturaSera;
                else
                    return 0;
            case 3:
                if (orariDb[j])
                    return orariDb[j].chiusuraSera;
                else
                    return 0;
        }
    }

    headers.push(<div className={classes.header} key={-1}>SCEGLI</div>); 
    for (let i=0; i < 7; i++) {
        headers.push(<div className={classes.header} key={i}>
            {week[i]}
        </div>);  
    }
    rows.push(<div className={classes.row} key="900">{headers}</div>);

    for (let i=0; i < 5; i++) {
        const cells = []
        cells.push(<div className={classes.header} key={900+i}>
            {FrazioniGiorno[i]}
        </div>);

        for (let j=0; j<7; j++){
            if (i==4) {
                cells.push(<div className={classes.header} key={j*(1+i)}>
                    <input 
                        id={"f"+i+"d"+j} 
                        name={"f"+i+week[j]} 
                        type="checkbox"
                        checked={festivoChecked[j]}
                        onChange={() => handleCheckboxChange((j))}
                    />
                </div>);
            } else {
                cells.push(<div className={classes.header} key={j*(1+i)}>
                <input 
                    id={"f"+i+"d"+j} 
                    name={"f"+i+week[j]} 
                    type="number"
                    min={0} max={24} 
                    step={0.01}
                    placeholder={getPlaceholderValue(i,j,orariDb)}
                />
            </div>);
            }
            
        }

        rows.push( <div className={classes.row} key={i}>
            {cells}
        </div>);
    }

    return(
        <div className={classes.box_table}>
            <h1>Calendario</h1>
            <div className={classes.table}>
                {rows}
            </div>
        </div>
    );
}