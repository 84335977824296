import React from 'react';

function ErrorPage() {
  // Estrai il parametro "messaggio" dall'URL
  const urlParams = new URLSearchParams(window.location.search);
  const messaggio = urlParams.get('messaggio');

  return (
    <div className="">
      <h1>Qualcosa è andato storto!</h1>
      <p>{messaggio}</p>
      <a href="/">Torna alla pagina iniziale</a>
    </div>
  );
}

export default ErrorPage;