import { Form, useNavigation, useLoaderData} from "react-router-dom";
import {callPatch} from "../../api/PuorkServer";
import { redirect } from "react-router-dom";

import classes from './Company.module.css';
import LoadingScreen from "../navigation/LoadingScreen";

export default function Company () {
    const navigation = useNavigation();
    const isSaving = navigation.state === 'submitting';
    const info = useLoaderData();

    return (
        <div className={classes.box}>
            { isSaving ? <LoadingScreen /> : null }
            <Form method="post" className={classes.form}>
                <div>
                    <span className={classes.title}>Identità </span>
                    <p>
                        <label htmlFor="nome">Nome azienda</label>
                        <input id="nome" name="nome" placeholder={info.data.nome} minLength={4}/>
                    </p>
                    
                    <p>
                        <label htmlFor="mail">Mail azienda</label>
                        <input id="mail" disabled name="mail" placeholder={info.data.mail} minLength={4}/>
                    </p>   

                    <p>
                        <label htmlFor="telefono">Telefono azienda</label>
                        <input id="telefono" name="telefono" placeholder={info.data.telefono} minLength={4}/>
                    </p>  

                    <p>
                        <label htmlFor="descrizione">Descrizione azienda</label>
                        <input id="descrizione" name="descrizione" placeholder={info.data.descrizione} minLength={4}/>
                    </p> 

                    <br />
                    <span className={classes.title}>Indirizzo </span>  
                    <div className={classes.indirizzo}>                   
                        <p>
                            <label htmlFor="provincia">Provincia</label>
                            <input id="provincia" disabled name="provincia" placeholder={info.data.address.provincia} minLength={4}/>
                        </p>  

                        <p>
                            <label htmlFor="comune">Comune</label>
                            <input id="comune" name="comune" placeholder={info.data.address.comune} minLength={4}/>
                        </p> 

                        <p>
                            <label htmlFor="indirizzo">Indirizzo</label>
                            <input id="indirizzo" name="indirizzo" placeholder={info.data.address.indirizzo} minLength={4}/>
                        </p> 

                        <p>
                            <label htmlFor="civico">Civico</label>
                            <input id="civico" name="civico" placeholder={info.data.address.civico} minLength={1}/>
                        </p> 
                    </div>
                </div>
                
                <button className={classes.btn} disabled={isSaving}>
                    { isSaving ? 'SALVATAGGIO...' : 'SALVA'}
                </button>
            </Form>
        </div>
    );
}

export async function action({request}) {
    const formData = await request.formData();

    const nome = formData.get('nome');
    const mail = formData.get('mail');
    const telefono = formData.get('telefono');
    const descrizione = formData.get('descrizione');
    const indirizzo = formData.get('indirizzo');
    const civico = formData.get('civico');
    const comune = formData.get('comune');

    if (nome && nome.length < 4) {
        // Nome azienda troppo corto
        return redirect("/error?messaggio=Il nome dell'azienda deve essere almeno di 4 caratteri");
    }

    if (!mail && !telefono && !nome && !indirizzo && !civico && !comune) {
        return redirect("/error?messaggio=Inserire almeno un dato tra: nome, mail, telefono e indirizzo");
    }

    const telefonoRegex = /^\d{10}$/;
    if (telefono && !telefono.match(telefonoRegex)) {
        return redirect("/error?messaggio=Il numero di telefono non è valido");
    }

    if ((indirizzo || civico || comune) && !(indirizzo && civico && comune)) {
        return redirect("/error?messaggio=Compilare tutti i campi di indirizzo, civico e comune");
    }

    const data = {
        nome,
        descrizione,
        mail,
        telefono,
        address: {
            indirizzo,
            civico,
            comune
        },
    };

    await callPatch('/company/update', data);
    return redirect("/settings/company");
}