import { useLoaderData, useNavigate} from "react-router-dom";

import Categoria from "../../component/settings/Categoria";

function ManageCategoryList() {
    const category = useLoaderData();
    const navigate = useNavigate();

    return (<>
        <div>
            <h3>Consiglio</h3>
            <p>
                Questa è la lista delle categorie dei prodotti della tua azienda, è importante pensarle e gestirle con ordine, il cliente 
                vuole sicuramente trovare velocemente il suo prodotto preferito.
            </p>
        </div>
        <button 
            style={{margin: 10}} 
            onClick={()=>{navigate("\add")}}
        >
            AGGIUNGI UNA CATEGORIA
        </button>
        <Categoria data={category.data} />
    </>)
}

export default ManageCategoryList;