import React from 'react';

function Settings() {
 
    return (
      <div className="App">
        <header className="App-header">
          
        </header>
      </div>
    );
  }
  
  export default Settings;
  