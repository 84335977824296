import { useState } from "react";
import { Form, useNavigation} from "react-router-dom";
import {callPatch} from "../../api/PuorkServer";
import { redirect } from "react-router-dom";

import classes from './Servizio.module.css';

import Spedizione from "./servizio/Spedizione";
import LoadingScreen from "../navigation/LoadingScreen";
import Calendario from "./servizio/Calendario";
import Drivers from "./servizio/Drivers";

export default function Servizio () {
    const navigation = useNavigation();
    const isSaving = navigation.state === 'submitting';

    return(
        <Form method="post" className={classes.form}>
            {isSaving ? <LoadingScreen /> : null}
            
            <Calendario />

            <Drivers />

            <Spedizione />

            <button className={classes.btn} disabled={isSaving}>
                { isSaving ? 'SALVATAGGIO...' : 'SALVA'}
            </button>
        </Form>
    );
}

export async function action({request}) {    
    const week = [
        "Lunedì",
        "Mertedì",
        "Mercoledì",
        "Giovedì",
        "Venerdì",
        "Sabato",
        "Domenica"
    ];
    const comuni = [
        "Ischia",
        "Forio",
        "Casamicciola Terme",
        "Lacco Ameno",
        "Barano",
        "Serrara Fontana"
    ]

    const formData = await request.formData();
    const orari = week.map((day, index) => ({
        aperturaMattina: formData.get(`f0${day}`),
        chiusuraMattina: formData.get(`f1${day}`),
        aperturaSera: formData.get(`f2${day}`),
        chiusuraSera: formData.get(`f3${day}`),
        festivo: formData.get(`f4${day}`),
    }));
    
    const fasce_consegna = formData.get('fasce_consegna');
    const driverAmount = formData.get('driverAmount');


    const coperturaComuni = [];
    comuni.map((item) => {
        if (formData.get(item) == "on")
            coperturaComuni.push(item)
    })

    const free = formData.get('free')
    const fixed = formData.get('fixed')
    const variable = formData.get('variable')

    //Calcolo costi di spedizione
    if (free == "on" || fixed == "on" || variable == "on" ){
        if (free == "on") {
            await callPatch('/company/service/update', {orari, fasce_consegna, driverAmount, coperturaComuni, free : true});
            return redirect("/settings/company");
        }

        if (fixed == "on"){
            const fixedCost = formData.get('fixedCost')
            await callPatch('/company/service/update', {orari, fasce_consegna, driverAmount, coperturaComuni, fixed: true, fixedCost});
            return redirect("/settings/company");
        }

        if (variable == "on"){
            const variableObj = {
                var1 : {
                    limit : formData.get('firstLimit'),
                    cost : formData.get('firstCost')
                },
                var2 : {
                    limit : formData.get('secondLimit'),
                    cost : formData.get('secondCost')
                },
                free : formData.get('freeLimit')
            }
            await callPatch('/company/service/update', {orari, fasce_consegna, coperturaComuni, variable : true, variableObj});
            return redirect("/settings/company");
        }
    } else {
        await callPatch('/company/service/update', {orari, fasce_consegna, driverAmount, coperturaComuni});
        return redirect("/settings/servizio");
    }
}