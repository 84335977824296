import FormAddProd from "../../component/settings/FormAddProduct";
import {callPost} from "../../api/PuorkServer";
import { redirect, useLoaderData } from "react-router-dom";

function AddProduct() {
    const categories = useLoaderData();
    return <FormAddProd categories={categories.data} />
}

export async function action({request}) {
    const formData = await request.formData();

    //forming ingredientiAgg
    const numRows = parseInt(formData.get("row"), 10);
    const ingredientiAgg = {};
    for (let idx = 0; idx < numRows; idx++) {
      const nomeIngrediente = formData.get("agg" + idx);
      const costoIngrediente = formData.get("aggcosto" + idx);
      ingredientiAgg[idx] = {
        nome: nomeIngrediente,
        costo: costoIngrediente,
      };
    }
    const ingredientiAggArray = Object.values(ingredientiAgg);
    //====================

    const data = {
        nome: formData.get('nomeProdotto'),
        descrizione: formData.get('descrizioneProdotto'),
        categoria: formData.get('categoriaProdotto'),
        prezzo: formData.get('prezzoProdotto'),
        image: formData.get('imageH'),
        ingredientiAgg : ingredientiAggArray
    };

    const response = await callPost('/company/product/add', data);

    
    if (response.status == 403){
      return window.location.href = "/settings/products?status=error&message=" + response.data.message;
    }

    return window.location.href = "/settings/products?status=success";
}

export default AddProduct;