import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import classes from './UserVerify.module.css';
import callApi, { callPost } from '../../api/PuorkServer';

function UserVerify({}) {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get('userId');
    const uniqueString = searchParams.get('uniqueString');
    const [error, setError] = useState();
    const [request, setRequest] = useState(false)
    const [success, setSuccess] = useState(false)

    const call = async () => {
        const response = await callApi(`/user/verify/${encodeURIComponent(userId)}/${encodeURIComponent(uniqueString)}`, 'GET');
        
        if (response.status != 200) {
            return setError(response.data.message)
        }
        else {
            setSuccess(true)
        }
    }

    useEffect(() => {
        call()
    }, [request, error])

    const handleClick = () => {
        return window.location.href = '/guest';
    }
    
    const handleRichiedi = async () => {
        const response = await callPost('/user/verify/again', {userId});

        console.log(response)
        
        if (response.status != 200)
            return setError(response.data.message)
        else
            setRequest(true)
    }

    return (
        <div className={classes.box}>
            { success
                ? <div>
                    <h1>Account Verificato Correttamente!</h1>
                    <button className={classes.btn} onClick={handleClick}>Capito!</button>
                </div>
                : <div>            
                    { request
                    ? <h1>Codice inviato, controlla la tua mail </h1>
                    : <div>
                        { error
                            ? <div>
                                <h2>{error}</h2>
                                <h4>Vuoi richiedere un nuovo codice?</h4>
                                <button onClick={handleRichiedi}>Clicca qui</button>
                            </div>
                            : <div>
                                <h1>Account Verificato Correttamente!</h1>
                                <button className={classes.btn} onClick={handleClick}>Capito!</button>
                            </div>
                        } 
                    </div>
                    }
                </div>
            }
        </div>
    );
}

export default UserVerify;
