import { useEffect, useState } from "react";
import { useLoaderData, useNavigate} from "react-router-dom";
import { Snackbar, Alert } from "@mui/material";
import Prodotti from "../../component/settings/Prodotti";

function ManageProductList() {
    const {products, categories} = useLoaderData();
    const navigate = useNavigate();
    //SNACKBAR && ALERT
    const [snackbar, setSnackbar] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const status = searchParams.get("status");
        const message = searchParams.get("message");

        if (status && status === "success") {
            if (message)
                setAlertMessage(message)
            else   
                setAlertMessage("Articolo Aggiunto con successo")
            setAlertType("success")
            setSnackbar(true);
        }
        
        if (status && message && status === "error") {
            setAlertMessage(message)
            setAlertType("error")
            setSnackbar(true);
        }
    }, []);

    const handleCloseSnackbar = () => {
        setSnackbar(false)
    }

    return (<>
        <div>
            <h3>Consiglio</h3>
            <p>Questa è la lista dei prodotti della tua azienda, sono proprio le pietanze che i tuoi clienti andranno ad acquistare.</p>
            <ul>
                <li key="1">Nel campo nome inserisci, appunto, il nome del prodotto, ti consigliamo di inserire un nome che il cliente ha già visto o con il quale ha confidenza </li>
                <li key="2">Nel campo descrizione inserisci una breve descrizione del prodotto o anche semplicemente la lista di ingredienti usati nella ricetta</li>
                <li key="3">Nel campo prezzo inserisci un numero da 0.50 a 999.99 senza includere lettere o simboli (neanche il simbolo €)</li>
                <li key="4">Nel campo aggiungi articoli puoi inserire degli ingredienti che il cliente può scegliere se aggiungere o meno sul prodotto che ha scelto, con il desiderato sovrapprezzo</li>
            </ul>
        </div>
        <button 
            style={{margin: 10}} 
            onClick={()=>{
                if (categories.data.length > 0)
                    navigate("add")
                else   
                    alert("Prima di aggiungere un prodotto devi inserire almeno una categoria")
            }}
        >
            AGGIUNGI UN PRODOTTO
        </button>
        <Prodotti data={products.data} categories={categories.data} />
        {   snackbar
            ? <Snackbar open={snackbar} autoHideDuration={10000} onClose={handleCloseSnackbar}>
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={alertType}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
            : null
        }
    </>)
}

export default ManageProductList;