import React from 'react';
import classes from './VersionInfo.module.css';

const VersionInfo = () => {
  const currentPath = window.location.pathname;

  if (currentPath === '/guest') return null;

  return (
    <div className={classes.container}>
      <p className={classes.text}>Puork Versione: Beta 0.2.80 build a</p>
    </div>
  );
};

export default VersionInfo;