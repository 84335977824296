import Company from '../../component/settings/Company';
import { useNavigation } from "react-router-dom";

function ManageCompany() {
    return (<>
        <div>
            <h3>Consiglio</h3>
            <p>
                Queste sono le impostazioni principali della tua azienda, fai molta attenzione a cosa scegli di modificare.
            </p>
        </div>
        <Company />
    </>)
}

export default ManageCompany;