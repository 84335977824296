import { Outlet } from "react-router-dom";
import TopBarNavigation from "../component/navigation/TopBarNavigation";

function RootLayout() { 
    
    return (
        <>
            <TopBarNavigation />
            <main style={{textAlign: "center"}}>
                <Outlet />
            </main>
        </>
    );
}

export default RootLayout;