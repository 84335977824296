import Modal from "../../../ui/Modal";
import classes from "./ManageOrderPopup.module.css";
import { setOrderStatus } from '../../../api/OrdersApi';
import { useState } from "react";
import RifiutaModal from "./RifiutaModal";
import MiniTimeLine from "../MiniTimeLine";

function ManageOrderPopup ({hideModal, info, status, updateOrders}) {
    const [rifiutaModal, setRifiutaModal] = useState(false)

    const showRifiutaModal = () => {
        setRifiutaModal(true)
    }

    const hideRifiutaModal = () => {
        setRifiutaModal(false)
        hideModal()
        updateOrders()
    }

    const preparazione = () => {
        setOrderStatus(info._id, 2)
        updateOrders()
        hideModal()
    }

    const spedisci = () => {
        setOrderStatus(info._id, 3)
        updateOrders()
        hideModal()
    }

    const spedito = () => {        
        setOrderStatus(info._id, 4)
        updateOrders()
        hideModal()
    }

    const consegnato = () => {        
        setOrderStatus(info._id, 5)
        updateOrders()
        hideModal()
    }

    const calcTot = (index) => {
        let totale = info.prodotti[index].prezzo
        info.prodotti[index].aggiuntivi.map((prodotto) => {
            totale = totale + prodotto.costo
        })
        return totale
    }

    switch(status){
        case 1:
            return (
                <Modal onClose={hideModal} >
                    <span className={classes.title}>ATTENZIONE !</span>
        
                    <div>
                        <p>Stai per accettare un ordine, sei sicuro che riesci a portarlo a termine? 
                        per favore ricontrolla l'intero ordine richiesto.</p>
                    </div>
        
                    <span className={classes.title}>Dettaglio Ordine</span>
        
                    <div className={classes.tab}>
                        <div className={classes.row}>
                            <div className={classes.col} style={{ width: '30%' }}> <h3>Nome Prodotto</h3></div>
                            <div className={classes.col} style={{ width: '50%' }}> <h3>Ingredienti Aggiuntivi</h3> </div>
                            <div className={classes.col} style={{ width: '20%' }}> <h3>Prezzo </h3> </div>
                        </div>
                        { info.prodotti.map((prodotto, idx) => {
                            return (
                                <div className={classes.row}>
                                    <div className={classes.col } style={{ width: '30%' }}> {prodotto.nome + " (" + prodotto.prezzo + "€) "} </div>
                                    { prodotto.aggiuntivi.length > 0
                                        ? <div className={classes.col } style={{ width: '50%'}}>
                                            {
                                                prodotto.aggiuntivi.map((ingrediente)=>{
                                                    return " (" + ingrediente.nome + " - " + ingrediente.costo + "€) "
                                                })
                                            }
                                            </div>
                                        : <div className={classes.col } style={{ width: '50%'}}> - </div>
                                    }
                                    <div className={classes.col } style={{ width: '20%' }}> 
                                        {
                                            calcTot(idx)
                                        }
                                        € 
                                    </div>
                                </div>
                            );
                        })}
                        <div className={classes.row}>
                            <div className={classes.col} style={{ width: '30%' }}> <span className={classes.title}>Totale</span> </div>
                            <div className={classes.col} style={{ width: '50%' }}><span className={classes.title}>---</span> </div>
                            <div className={classes.col} style={{ width: '20%' }}> <span className={classes.title}>{info.totale}€</span> </div>
                        
                        </div>   
                    </div>
        
                      
                           

                    <MiniTimeLine id={info._id} hideModal={hideModal} updateOrders={updateOrders} />
                    <br />
                    <button className={classes.btn} onClick={() => showRifiutaModal()}> RIFIUTA ORDINE </button>

                    { rifiutaModal && <RifiutaModal id={info._id} hideModal={hideRifiutaModal} />}
                </Modal>
            );
        case 2:
            return (
                <Modal onClose={hideModal} >
                    <h3>ATTENZIONE !</h3>
                    <p>
                        <b>L'ordine è in lavorazione?</b> il cliente sarà informato che il suo ordine è quasi puorkonto, gnam gnam.
                    </p>
                    <button className={classes.btn} onClick={() => preparazione()}> IN LAVORAZIONE </button>
                    <br />
                    <button className={classes.btn} onClick={() => hideModal()}> INDIETRO </button>
                </Modal>
            );
        case 3:
            return (
                <Modal onClose={hideModal} >
                    <h3>ATTENZIONE !</h3>
                    <p>
                    <b>L'ordinazione è pronta ed è in viaggio?</b> assicurati di <b>controllare</b> che <b>tutti i prodotti</b> sono stati realizzando seguendo i <b>desideri del cliente</b> e non dimenticare nulla.
                    </p>
                    <button className={classes.btn} onClick={() => spedisci()}> IN CONSEGNA </button>
                    <br />
                    <button className={classes.btn} onClick={() => hideModal()}> INDIETRO </button>
                </Modal>
            );
        case 4:
            return (
                <Modal onClose={hideModal} >
                    <h3>L'ordine è stato consegnato?</h3>                    
                    <button className={classes.btn} onClick={() => spedito()}> Si! </button>
                </Modal>
            );
    }
}

export default ManageOrderPopup