import axios from 'axios';
import { getAuthToken } from '../helper/auth';

const BASE_URL = "http://127.0.0.1:3000";
//const BASE_URL = "https://us-central1-puork-app.cloudfunctions.net/app";
const TIMEOUT = 5000;

export default async function callApi (url = '/', method = 'GET', body = {}) {
  const token = getAuthToken();
  let auth = ''

  if ( token ){
    auth = `Bearer ${token}`;
  }


  const api = axios.create({
    baseURL: BASE_URL + url,
    timeout: TIMEOUT,
    method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': auth
    },
    body: body,
    params : body,
    data: body
  });

  try {
    const response = await api.call();
    return response;
  } catch (error) {
    return error.response
  }
  
}

export async function callPost (url = '/', body = {}) {
  try {
    const token = getAuthToken();
    let auth = ''

    if ( token ){
      auth = `Bearer ${token}`;
    }

    const api = axios.create({
      baseURL: BASE_URL,
      timeout: TIMEOUT,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': auth
      },
    });

    try {
      const response = await api.post(url, body);
      return response;
    } catch (error) {
      return error.response
    }

  } catch (error) {
    return error.message
  }
}

export async function callPatch (url = '/', body = {}) {
  const token = getAuthToken();
  let auth = ''

  if ( token ){
    auth = `Bearer ${token}`;
  }

  const api = axios.create({
    baseURL: BASE_URL,
    timeout: TIMEOUT,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': auth
    },
  });

  const response = await api.patch(url, body);  
  
  return response;
}