import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    isInService: false,
    isInKitchen: false,
    timeLine: null
}

const serviceSlice = createSlice({
    name: 'service',
    initialState: initialState,
    reducers: {
        serviceOn(state){
            state.isInService = true;
        },
        serviceOff(state){
            state.isInService = false;
        },
        kitchenServiceOn(state){
            state.isInKitchen = true;
        },
        kitchenServiceOff(state){
            state.isInKitchen = false;
        },
        updateTimeLine(state, action){
            return {
                ...state,
                timeLine: action.payload
            };
        },
    }
})

export const serviceActions = serviceSlice.actions;

export default serviceSlice;