import { useActionData, Form, json, redirect } from "react-router-dom";
import classes from './Register.module.css';

import { callPost } from "../api/PuorkServer";

function Register() {
    const data = useActionData();

    const handleIndietro = () => {
        return window.location.href = '/';
    }

    return (
        <div className={classes.slideup} >
            <Form method="post" className={classes.form}>
                <h1 style={{textAlign: "center"}}>Registra la tua Società!</h1>
                <h3 style={{textAlign: "justify"}}>
                    Entrare nel mondo di <b>Puork</b> è <b>facile</b> e <b>gratuito</b>! Compila il modulo per inviare la richiesta d'iscrizione e, successivamente, 
                    un nostro tecnico puorkoso vi ricontatterà telefonicamente
                </h3>
                {data && data.error && <h3>{data.error}</h3>}
                {data && data.message && <p>{data.message}</p>}
                <p>
                    <label htmlFor="email">Email</label>
                    <input id="email" type="email" name="email" required />
                </p>
                <p>
                    <label htmlFor="nome">Nome Azienda</label>
                    <input id="nome" type="text" name="nome" required />
                </p>
                <p>
                    <label htmlFor="piva">P.Iva</label>
                    <input id="piva" type="number" name="piva" required />
                </p>
                <p>
                    <label htmlFor="password">Crea una Password</label>
                    <input id="password" type="password" name="password" required />
                </p>
                <p>
                    <label htmlFor="telefono">Numero Telefonico</label>
                    <input id="telefono" type="number" name="telefono" required />
                </p>
                <p>
                    <label htmlFor="adress">Indirizzo di lavoro</label>
                    <input id="adress" type="text" name="adress" required />
                </p>
                {/*  Tags con un sistema grafico che fa aggiungere i tag o scrivendoli o cliccando su suggerimenti */}
                <p>
                    <label htmlFor="descrizione">Descrizione</label>
                    <input id="descrizione" type="text" name="descrizione" required />
                </p>
                <div className={classes.actions}>
                    <button className={classes.btn}>Invia Richiesta</button>
                </div>
                
                <div onClick={handleIndietro} className={classes.btn}>
                    Indietro
                </div>
            </Form>
        </div>
    )
}

export async function action({request}){
    try {
        const data = await request.formData();
        
        const authData = { 
            nome : data.get('nome'),
            mail : data.get('email'),
            piva : data.get('piva'),
            password : data.get('password'),
            telefono : data.get('telefono'),
            address: data.get('adress'),
            descrizione: data.get('descrizione'),
        }

        await callPost('/company/signup', authData);

        return redirect("/");
    } catch (e) {
        throw json({message: 'Authentication Error'}, {status: 400});
    }
}

export default Register;