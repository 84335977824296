import React, { useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table'; 
import { deleteCategory, modifyCategory } from '../../api/CompanyApi';
import LoadingScreen from '../navigation/LoadingScreen';

import { MRT_Localization_IT } from 'material-react-table/locales/it';
import DeleteIcon from '@mui/icons-material/Delete';
import { MenuItem } from '@mui/material';

function Categoria ({data}) {
    const [isLoading, setIsLoading] = useState(false)
    const handleDelete = async (row) => {
        if (window.confirm('Sei sicuro di voler eliminare questa categoria?')){
            setIsLoading(true)
            await deleteCategory(data[row.id]._id)            
            setIsLoading(false)
            window.location.reload()
        }
        else
          return;
    };

    const handleEdit = async ({ exitEditingMode, row, values }) => {
        setIsLoading(true)
        await modifyCategory(data[row.id]._id, values.nome)
        exitEditingMode()        
        setIsLoading(false)
    };

    const columns = useMemo(() => [
        {
            header: 'Nome',
            accessorKey: 'nome', 
            size: 300
        }
    ],[],);

    return (
        <div>
            {isLoading ? <LoadingScreen /> : null}
            <MaterialReactTable
                columns={columns}
                data={data}
                enableColumnOrdering
                enableGlobalFilter={false}
                enableEditing
                onEditingRowSave={handleEdit}                   
                localization={MRT_Localization_IT}            
                renderRowActionMenuItems={({ row }) => [                    
                    <MenuItem
                        key={1}
                        onClick={() => handleDelete(row)}
                        sx={{m: 0}}
                    >
                        <DeleteIcon />
                        Elimina
                    </MenuItem>
                ]}
            />
        </div>
    );
}
  
export default Categoria;
