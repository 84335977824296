import { redirect, useNavigate } from "react-router-dom";

import classes from './Guest.module.css';


function Guest() {
    const navigate = useNavigate(); 

    const handleSignup = () => {
        navigate("/signup")
    }

    const handleSign = () => {
        navigate("/sign")
    }

    return (
        <>
            <div className={classes.first_impression} >
                <div className={classes.box_what}>
                    <span className={classes.title}>COS'E' PUORK?</span>
                    <span className={classes.text}><b>Puork</b> è un'app che <b>ti permette di ordinare cibo</b> a casa tua in modo facile e veloce!</span>
                    <br></br>
                    <ul className={classes.text}>
                        <li>Basta perdere tempo in coda</li>
                        <li>Nessun spreco di benzina</li>
                        <li>Minori emissioni di co2</li>
                        <li>Nessun errore di comunicazione, tutto preimpostato e scritto in chiaro</li>
                    </ul>
                    <br></br>
                    <span className={classes.title}>Grazie a Puork le chiamate per ordinare diventano superflue!</span>
                </div>
            </div>

            <div className={classes.container_sub}>
                    <div onClick={handleSignup} className={classes.box_btn}>
                        <span className={classes.text_btn}>REGISTRA LA TUA AZIENDA SU PUORK</span>
                    </div>
                    <div onClick={handleSign} className={classes.box_btn}>
                        <span className={classes.text_btn}>ACCEDI AL GESTIONALE PER AZIENDE</span>
                    </div>
            </div>

            <div className={classes.container_trd}>
                <span className={classes.title}>
                    Scarica l'app <br /> 
                    Gratuitamente ora! <br />
                    <img src="stores.png" className={classes.img2} />
                </span>
                <img src="phone.png" className={classes.img} />
            </div>

            <div className={classes.container_footer}>
                <div className={classes.container_sub_footer}>
                    <span className={classes.title}>Puork S.r.l. P.IVA : 10360000 </span>
                    <span className={classes.title}> Sede legale: Via circumvallazione 92</span>
                </div>
            </div>
        </>
    )
}

export default Guest;

