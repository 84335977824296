import { useEffect, useState } from "react";

import classes from './Servizio.module.css';
import { useLoaderData } from "react-router-dom";


export default function Drivers () {
    const comuni = [
        "Ischia",
        "Forio",
        "Casamicciola Terme",
        "Lacco Ameno",
        "Barano",
        "Serrara Fontana"
    ]
    const [comuniChecked, setComuniChecked] = useState({
        "Ischia": false,
        "Forio": false,
        "Casamicciola Terme": false,
        "Lacco Ameno": false,
        "Barano": false,
        "Serrara Fontana": false
    })

    const handleComuniCheckboxChange = (comune) => {
        setComuniChecked((prevState) => {
            return {
                ...prevState,
                [comune]: !prevState[comune],
            };
        });
    };

    const response = useLoaderData();
    const driverAmt = response.data.driverAmt;
    const comuniInit = response.data.comuni;

    useEffect(() => {
        const nuoviComuniChecked = { ...comuniChecked };

        comuniInit.forEach(comune => {
            if (nuoviComuniChecked.hasOwnProperty(comune)) {
                nuoviComuniChecked[comune] = true;
            }
        });

        setComuniChecked(nuoviComuniChecked);
    }, [comuniInit]);

    return(
        <>
            <div className={classes.box}>
                <h1>Gestione Uscite/Spedizioni</h1>
                <div className={classes.box_drivers}>
                    <div className={classes.element_drivers}>
                        <h3>Intervalli di consegna</h3>
                        <select id="fasce_consegna" name="fasce_consegna">                        
                            <option value="">Scegli</option>
                            <option value="10">Ogni 10 minuti</option>
                            <option value="15">Ogni 15 minuti</option>
                            <option value="20">Ogni 20 minuti</option>
                            <option value="30">Ogni 30 minuti</option>
                        </select>
                    </div>                
                    
                    <div className={classes.element_drivers}>
                        <h3>Quantità Driver</h3>
                        <input id="driverAmount" name="driverAmount" min="1" type="number" placeholder={driverAmt}/>
                    </div>
                </div>
            </div>

            <div className={classes.box}> 
                <h1>Comuni coperti da spedizione:</h1>
                <div className={`${classes.box_comuni} clearfix`}>
                { 
                    comuni.map((item, index) => {
                        return <div key={index} className={classes.element_comuni}>
                            <label htmlFor={item}>{item}</label>
                            <input 
                                id={item} 
                                name={item} 
                                type="checkbox"
                                checked={comuniChecked[item]}
                                onChange={() => handleComuniCheckboxChange(item)}
                            />
                        </div>
                    })
                }
                </div>
                
            </div>
        </>
    );
}
