import React, { useState } from 'react';

import classes from './Popup.module.css';

const Popup = ({ onActivate, title, message, buttonMessage }) => {

  return (
    <div className={classes.overlay} onClick={onActivate} > 
        <div className={classes.popup} onClick={onActivate}>
            <span className={title} >{title}</span>
            <span className={classes.message}>{message}</span>
            <br />
            { buttonMessage
                ? <button className={classes.button} onClick={onActivate} >{buttonMessage}</button>
                : null
            }
        </div>
    </div>
    
  );
};

export default Popup;