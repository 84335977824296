import classes from './ListElement.module.css';
import { useEffect, useState } from 'react';
import OrderAssistance from './modal/OrderAssistance';

function Element({items, showProductsDetailModal, showManageModal, retriveInfo, type, handleAssistance}) {
    const [flash, setFlash] = useState(false)
    //Assistance Modal
    const [help, setHelp] = useState(false)

    const click_visualizza = () => {
        showProductsDetailModal()
        retriveInfo(items)
    }

    useEffect(()=>{
        setTimeout(() => {
            setFlash(!flash);
        }, 1500);
    },[flash])

    const manage = (id, status) => {
        retriveInfo(items)
        showManageModal(id, status+1) //setOrderStatus(id, status+1)
    }

    if (items == null)
        return

    if (type == 0){
        return  <div className={classes.row}>    
            <div className={flash ? classes.cell_flash : classes.cell_flash2} style={{ width: '20%' }}> {items.indirizzo ? items.indirizzo : "-"} </div>
            <div className={flash ? classes.cell_flash : classes.cell_flash2} style={{ width:  '8%' }}> {typeof items.time.userReq.orario === "number" && items.time.userReq.orario !== 0 ? (items.time.userReq.orario / 100).toFixed(2) : "Appena Possibile"}</div>
            <div className={flash ? classes.cell_flash : classes.cell_flash2} style={{ width:  '8%' }}> <button className={classes.btn} onClick={() => click_visualizza()}>visualizza</button> </div>
            <div className={flash ? classes.cell_flash : classes.cell_flash2} style={{ width:  '8%' }}> {items ? items.totale : "0.00"} </div>
            <div className={flash ? classes.cell_flash : classes.cell_flash2} style={{ width: '10%' }}> <button className={classes.btn} onClick={() => manage(items._id, type)}>{type != 4 ? "Gestisci" : "-"}</button></div>
            <div className={flash ? classes.cell_flash : classes.cell_flash2} style={{ width: '10%' }}> <button className={classes.btn} onClick={()=> handleAssistance(items._id)}>assistenza</button></div>
        </div>
    } else if (type == 4) {
        return  <div className={classes.row}>    
            <div className={classes.cellSpedito} style={{ width: '20%' }}> {items.indirizzo ? items.indirizzo : "-"} </div>
            <div className={classes.cellSpedito} style={{ width:  '8%' }}> {typeof items.time.userReq.orario === "number" && items.time.userReq.orario !== 0 ? (items.time.userReq.orario / 100).toFixed(2) : "Appena Possibile"}</div>
            <div className={classes.cellSpedito} style={{ width:  '8%' }}> <button className={classes.btn} onClick={() => click_visualizza()}>visualizza</button> </div>
            <div className={classes.cellSpedito} style={{ width:  '8%' }}> {items ? items.totale : "0.00"} </div>
            <div className={classes.cellSpedito} style={{ width: '10%' }}> <button className={classes.btn} onClick={() => manage(items._id, type)}>{type != 4 ? "Gestisci" : "-"}</button></div>
            <div className={classes.cellSpedito} style={{ width: '10%' }}> <button className={classes.btn} onClick={()=> handleAssistance(items._id)}>assistenza</button></div>
        </div>
    } else {
        return  <div className={classes.row}>    
            <div className={classes.cell} style={{ width: '20%' }}> {items.indirizzo ? items.indirizzo : "-"} </div>
            <div className={classes.cell} style={{ width:  '8%' }}> {typeof items.time.userReq.orario === "number" && items.time.userReq.orario !== 0 ? (items.time.userReq.orario / 100).toFixed(2) : "Appena Possibile"}</div>
            <div className={classes.cell} style={{ width:  '8%' }}> <button className={classes.btn} onClick={() => click_visualizza()}>visualizza</button> </div>
            <div className={classes.cell} style={{ width:  '8%' }}> {items ? items.totale : "0.00"} </div>
            <div className={classes.cell} style={{ width: '10%' }}> <button className={classes.btn} onClick={() => manage(items._id, type)}>{type != 4 ? "Gestisci" : "-"}</button></div>
            <div className={classes.cell} style={{ width: '10%' }}> <button className={classes.btn} onClick={()=> handleAssistance(items._id)}>assistenza</button></div>
        </div>
    }
}

export function ElementTemplate(){
    return  <div className={classes.row}>
        <div className={classes.header} style={{ width: '20%' }}> INDIRIZZO </div>
        <div className={classes.header} style={{ width:  '8%' }}> ORARIO DESIDERATO </div>
        <div className={classes.header} style={{ width:  '8%' }}> ORDINE </div>
        <div className={classes.header} style={{ width:  '8%' }}> TOTALE (€) </div>
        <div className={classes.header} style={{ width: '10%' }}> GESTISCI</div>
        <div className={classes.header} style={{ width: '10%' }}> ASSISTENZA</div>
    </div>
}

export default Element;