import callApi from "../api/PuorkServer";

export async function modifyProduct (id, nome, descrizione, prezzo, categoria ) {
    const response = await callApi('/company/product/modify', "POST", {id, nome, descrizione, prezzo, categoria});
    return response;
}

export async function modifyProductAgg (idAgg, newNome, newCosto, oriNome, oriCosto ) {
    const response = await callApi('/company/product/agg/modify', "POST", {idAgg, nome: newNome, costo : newCosto, oriNome, oriCosto });
    return response;
}

export async function deleteProduct (id) {
    const response = await callApi('/company/product/delete', "POST", {id});
    return response;
}

export async function deleteProductAgg (idAgg, oriNome, oriCosto ) {
    const response = await callApi('/company/product/agg/delete', "POST", { idAgg, oriNome, oriCosto });
    return response;
}

export async function deleteCategory (id) {
    const response = await callApi('/company/category/delete', "POST", {id});
    return response;
}

export async function modifyCategory (id, nome) {
    const response = await callApi('/company/category/modify', "POST", {id, nome});
    return response;
}

export async function modifyInService (status) {
    const response = await callApi('/company/setservice', "POST", {status});
    return response;
}

export async function sendMail (mail, oggetto, messaggio) {
    const response = await callApi('/utils/sendmail', "POST", {mail, oggetto, messaggio})
    return response;
}