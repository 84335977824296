import Element, { KitchenElementTemplate } from "./ListElementKitchen";
import classes from './ListKitchen.module.css';
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getOrders } from "../../api/OrdersApi";

function KitchenList(props) { 
    const [ordersLavoraz, setOrdersLavoraz] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const orders = useSelector(state => state.orders.orders);


    useEffect(()=>{
        if (orders){
            const oggettiFiltrati = orders.filter(oggetto => oggetto.status === 1);
            const ofettiFiltrati2 = orders.filter(oggetto => oggetto.status === 2);
            setFilteredOrders(oggettiFiltrati)
            setOrdersLavoraz(ofettiFiltrati2)
        }
    }, [orders])

    if (filteredOrders || ordersLavoraz)
        return (
            <>  
                { filteredOrders 
                    ? <>
                        <h2>ORDINI DA LAVORARE</h2> 
                        <div className={classes.table}>            
                            <KitchenElementTemplate />
                            { filteredOrders
                                .map(( order => (<>                               
                                    {   order.prodotti                                        
                                            .filter(prod => prod.descrizione !== "spedizione")
                                            .map((prod => 
                                                <Element
                                                    key={prod._id}
                                                    items={prod}   
                                                    id={order.numero}
                                                    type={1}
                                                    orario={order.time.companyRes.orario}                            
                                                />
                                            ))
                                    }                       
                                </>)
                            ))}
                        </div>
                    </>
                    : null
                }
                <br />
                { ordersLavoraz 
                    ? <>
                        <h2>ORDINI IN LAVORAZIONE</h2>
                        <div className={classes.table}>
                            <KitchenElementTemplate />
                            { ordersLavoraz.map(( order => (<>                               
                                    {   order.prodotti.map((prod => 
                                            <Element
                                                key={prod._id}
                                                items={prod}   
                                                id={order.numero}
                                                type={2}
                                                orario={order.time.companyRes.orario}                          
                                            />
                                        ))
                                    }                       
                                </>)
                            ))}
                        </div>
                    </>
                    : null
                }     
            </>
        )
    else
        return null;
    
}

export default KitchenList;