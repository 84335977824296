import { useState } from "react"
import { Form, useActionData, redirect, json} from 'react-router-dom';
import classes from "./Help.module.css"
import { Business, Favorite, People, ReceiptLong } from "@mui/icons-material";
import { callPost } from "../api/PuorkServer";

export default function Help() {
    const [profile, setProfile] = useState(false)
    const [order, setOrder] = useState(false)
    const [company, setCompany] = useState(false)
    const [careers, setCareers] = useState(false)

    const update = (type) => {
        switch (type){
            case 0: 
                setProfile(true); 
                setOrder(false); 
                setCompany(false); 
                setCareers(false);
                break;
            case 1: 
                setProfile(false); 
                setOrder(true); 
                setCompany(false); 
                setCareers(false);
                break;
            case 2: 
                setProfile(false); 
                setOrder(false); 
                setCompany(true); 
                setCareers(false);
                break;
            case 3: 
                setProfile(false); 
                setOrder(false); 
                setCompany(false); 
                setCareers(true);
                break;
        }
    }

    return (<>
        <Form method="post" className={classes.form}>
            <input type="hidden" name="isProfile" value={profile} />
            <input type="hidden" name="isOrder" value={order} />
            <input type="hidden" name="isCompany" value={company} />
            <input type="hidden" name="isCareers" value={careers} />

            <div className={classes.table}>                
                <div className={classes.row}>
                    <div className={classes.cell} onClick={()=>update(0)}>
                        <h2>PROFILO DELLA SOCIETA' </h2> <People />
                    </div>
                    <div className={classes.cell} onClick={()=>update(1)}>
                        <h2>PROBLEMA SU UN ORDINE</h2> <ReceiptLong />
                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.cell} onClick={()=>update(2)}>
                        <h2>CONSULENZA FINANZIARIA</h2> <Business />
                    </div>
                    <div className={classes.cell} onClick={()=>update(3)}>
                        <h2>LAVORA CON NOI</h2> <Favorite />
                    </div>
                </div>
            </div>          

            { profile 
                ? <div className={classes.content}>
                    <h3>Hai problemi col tuo profilo?</h3>
                    <p>Contattaci al numero d'assistenza: <b>333-3333333</b> oppure compila il seguente form (sarai ricontattato il prima possibile):</p>
                    <p>
                        <label htmlFor="email">Inserisci la tua mail</label>
                        <input id="email" type="email" name="email" required />
                    </p>
                    <p>
                        <label htmlFor="titolo">Inserisci un titolo</label>
                        <input id="titolo" type="text" name="titolo" required />
                    </p>
                    <p>
                        <label htmlFor="msg">Descrivi il problema</label>
                        <input id="msg" type="text" name="msg" required />
                    </p>
                    <p>
                        <label htmlFor="telefono">Inserisci recapito telefonico</label>
                        <input id="telefono" type="text" name="telefono" required />
                    </p>
                    <div className={classes.actions}>
                        <button>Save</button>
                    </div>
                </div> 
                : null
            }

            { order 
                ? <div className={classes.content}>
                    <span className={classes.title}>Supporto puorkoso per il tuo ordine?</span>
                    <br />
                    <span className={classes.msg}>Puoi chiamare il <b>supporto telefonico</b> gratuito a questo numero 327 5408201</span>
                    <br />
                    <span className={classes.msg}>Puoi scrivere alla <b>chat d'assistenza whatsapp</b> a questo numero 327 5408201</span>
                    <br />
                </div> 
                : null
            }

            { company 
                ? <div className={classes.content}>
                    Iscrivi la tua azienda su PUORK
                </div> 
                : null
            }

            { careers 
                ? <div className={classes.content}>
                    Lavora Con noi
                </div> 
                : null
            }
        </Form>
    </>)
}

export async function action({request}){
    try {
        const data = await request.formData();
        let category = ""

        if (data.get('isProfile'))
            category = "Profilo"
        else if (data.get('isOrder'))
            category = "Ordine"
        else if (data.get('isCompany'))
            category = "Consulenza"
        else if (data.get("isCareers"))
            category = "Carriera"
        else category = "Varia"
        
        
        const finalData = { 
            senderMail : data.get('email'),
            titolo : data.get('titolo'),
            messaggio : data.get('msg'),
            telefono : data.get('telefono'),
            categoria : category
        }

        const response = await callPost('/company/ticket/create', finalData);
        alert("Segnalazione effettuata!")
        return redirect("/");
    } catch (e) {
        console.log(e)
        throw json({message: 'Authentication Error'}, {status: 400});
    }
}
