function Analytics() {
    return (<div>
        <h2>Analytics</h2>
        <h3>In arrivo con la versione Puork 1.1.0 !!</h3>
        <p>Questa pagina ti aiuterà a controllare ed analizzare i tuoi ordini, tutti i dati saranno processati automaticamente e potrai accedere ad informazioni come: </p>
        <ul>
            <li>I prodotti più richiesti</li>
            <li>I prodotti meno richiesti</li>
            <li>Gli ingredienti aggiuntivi più selezionati</li>
            <li>Media giornaliera e mensile di ordini e di prodotti</li>
            <li>E tanto altro ancora...</li>
        </ul>
    </div>)
}

export default Analytics;