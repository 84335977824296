import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useIdleTimer } from 'react-idle-timer'

import classes from './Homepage.module.css';
import { serviceActions } from '../store/service';
import { ordersActions } from '../store/orders';

import Summary from '../component/CompanySummary';
import Popup from '../component/Popup';
import List from '../component/ordini/List';
import KitchenList from '../component/ordini/ListKitchen';
import TimeLine from '../component/ordini/TimeLine';
import { modifyInService } from '../api/CompanyApi';
import { getOrders } from '../api/OrdersApi';
import LoadingScreen from '../component/navigation/LoadingScreen';
import Plans from './Plans';

const timeout = 120_000 

function HomePage({salazar}) {
	const dispatch = useDispatch();
	const isInService = useSelector(state => state.service.isInService);
	const isInKitchen = useSelector(state => state.service.isInKitchen);
	const orders = useSelector(state => state.orders.orders)
	const [isLoading, setIsLoading] = useState(false)

	//Update management
	const [update, setUpdate] = useState(0);
	//const [orders, setOrders] = useState([]);
	const [firstTime, setFirstTime] = useState(false)

	//Activity/Unactivity management
	const [state, setState] = useState('Active')
	//const [remaining, setRemaining] = useState(timeout)
	const [open, setOpen] = useState(false)

	//popup calling
	const onIdle = () => {
		setState('Idle')
		setOpen(true)
	}

	//user come back - close popup
	const onActive = () => {
		setState('Active')
		setOpen(false)
		call()
	}
	
	const { activate } = useIdleTimer({
		onIdle,
		onActive,
		timeout,
		throttle: 500
	})

	useEffect(()=>{
		call();
		const timeoutId = setTimeout(() => {
			setUpdate(prevUpdate => prevUpdate + 1);
		}, 30000);

		return () => {
			clearTimeout(timeoutId);
		};
	}, [update])

	const call = async () => {
		setIsLoading(true)
		if (!firstTime){
			setFirstTime(true)
			const response = await getOrders();
			dispatch(ordersActions.update(response.data))
		}
		if (isInService){
			if (state == 'Active'){
				const response = await getOrders();
				dispatch(ordersActions.update(response.data))
			}
		}
		setIsLoading(false)
  	}

  	const callFastUpdate = async () => {
		const response = await getOrders();
		dispatch(ordersActions.update(response.data))
  	}


  	const manageService = async () => {
    	setIsLoading(true)
    	if( isInService ){
      		await modifyInService(0)
      		dispatch(serviceActions.serviceOff())
    	} else {
			await modifyInService(1)
			dispatch(serviceActions.serviceOn())
    	}
    	setIsLoading(false)
	}

	const manageKitchenService = () => {
		if( isInKitchen ){
		dispatch(serviceActions.kitchenServiceOff())
		} else {
			dispatch(serviceActions.kitchenServiceOn())
		}
	}

	if (!isInKitchen) {
		if (isInService) {
			return (
			  	<div className={classes.App}>
				  	{ isLoading
					  	? <LoadingScreen />
					  	: null
				  	}
				  	{ open
					  	? <Popup  onActivate={onIdle} message={"Sei ancora tra noi?"} />
					  	: null
				  	}
					<List orders={orders} type={0} title="Attesa di risposta" updateOrders={callFastUpdate} />
					<List orders={orders} type={1} title="Accettati da preparare" updateOrders={callFastUpdate} />
					<List orders={orders} type={2} title="Lavorazione (in cucina)" updateOrders={callFastUpdate} />
					<List orders={orders} type={3} title="In consegna" updateOrders={callFastUpdate}  />
					<List orders={orders} type={4} title="Spediti e consegnati" updateOrders={callFastUpdate}  />
					<List orders={orders} type={-1} title="Contestati" />
					<hr className={classes.line} />
					<hr className={classes.line} />
					<TimeLine />
					<button className={classes.btn} onClick={() => manageKitchenService()}> GESTIONE CUCINA </button>
					<button className={classes.btn} onClick={() => manageService()}> ESCI DAL SERVIZIO </button>
			  	</div>
			);
		} else {
			return (
			  <div className={classes.App}>
				{ isLoading
				   ? <LoadingScreen />
				   : null
				}				
				<Summary />
				<button className={classes.btn} onClick={() => manageService()}> ENTRA IN SERVIZIO </button>
				<Plans preview={true} />
			  </div>
			);
		}
	} else {
		return (
			<div>        
				{ isLoading
					? <LoadingScreen />
					: null
				}
				<KitchenList />
				<button className={classes.btn} onClick={() => manageKitchenService()}> ESCI DALLA GESTIONE CUCINA </button>
			</div>	
		);
	}
}
  
export default HomePage;
  