import callApi from "../api/PuorkServer";

export async function getProductsList () {
    const products = await callApi('/company/product/list');
    const categories = await callApi('/company/product/category/list');
    return {products, categories};
}

export async function getCategoryList () {
    const response = await callApi('/company/product/category/list');
    return response;
}

export async function getFasceSettings () {
    const response = await callApi('/company/service/fasce');
    return response;
}

export async function getOrariSettings () {
    const response = await callApi('/company/service/orari');
    return response;
}

export async function getSettings () {
    const response = await callApi('/company/service/settings')
    return response;
}

export async function getInfo () {
    const response = await callApi('/company/info');
    return response;
}