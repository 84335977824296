import { useState } from 'react';
import PlanCards from '../component/plans/PlanCards';
import PlanDialog from '../component/plans/PlanDialog';

import classes from './Plans.module.css';

/* ===== */
const Plans = ({preview}) => {
    const [dialog, setDialog] = useState(false)
    const [plan, setPlan] = useState(0)

    const handleCloseDialog = () =>{
        setDialog(false)
    }

    const handlePlan = (item) => {
        /*  ITEM VALUE :
            1: SPECIAL MENSILE
            2: SPECIAL ANNUALE
            3: PREMIUM MENSILE
            4: PREMIUM ANNUALE
        */

        switch (item){
            case 1: 
                setPlan("Special Mensile")
                break;
            case 2:
                setPlan("Special Annuale")
                break;
            case 3:                
                setPlan("Premium Mensile")
                break;
            case 4:
                setPlan("Premium Annuale")
                break;
        }
        
        setDialog(true)
    }

    return <>
        { preview
            ? <>
                <div className={classes.textContainerSpoiler}>
                    <h3>Ti piace Puork?</h3>
                    <h3>Migliora il tuo account!</h3> <a href="/plans" >CLICCAMI</a>
                </div>
            </>
            : <div className={classes.plansContainer}>
                <div className={classes.textContainer}>
                    <h3>Ti piace Puork?</h3>
                    <h3>Migliora il tuo account!</h3>
                </div>

                <PlanCards handlePlan={handlePlan} />

                { dialog 
                    ? <PlanDialog plan={plan} DialogClose={handleCloseDialog} />
                    : null
                }
            </div>
        }
        
    </>
}
  
  export default Plans;
  