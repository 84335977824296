import { Form, useNavigation } from "react-router-dom";

import classes from "./FormAddProduct.module.css";
import LoadingScreen from "../navigation/LoadingScreen";

export default ({categories}) => {
    const navigation = useNavigation();
    const isSaving = navigation.state === 'submitting';
    

    return (<Form method="post" className={classes.form}>
        {isSaving ? <LoadingScreen /> : null}
        <h1>Aggiungi Categoria</h1>

        <div className={classes.bodyPrd}>
            <h3>Informazioni </h3>

            <p>
                <label htmlFor="nome">Nome Categoria</label>
                <input id="nome" name="nome" required minLength={4}/>
            </p>            
        </div>
        
        <div className={classes.actions}>
            <button disabled={isSaving}>
                { isSaving ? 'SALVATAGGIO...' : 'SALVA'}
            </button>
        </div>
    </Form>);
}