import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import classes from './PlanCards.module.css';


/* Text style */
const fF = 'Brush Script MT, cursive'
const fSTitle = 20
const fWTitle = 'medium'
const fCTitle = 'white'
const lHTitle = '20px'
const fSSubTitle = 15
const fWSubTitle = 'none'
const lHSubTitle = '20px'
/* ===== */
const PlanCards = ({handlePlan}) => {
    return <div className={classes.container}>
        <div className={classes.card}>
            <span className={classes.card_title}>
                FREE
            </span>
            <hr />
            <List>
                <ListItem>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="20 Prodotti" 
                        secondary="Puoi aggiungere fino a 20 prodotti da far comprare ai tuoi clienti"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                            color: fCTitle
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon  sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="3 Ingredienti Extra" 
                        secondary="Puoi aggiungere 3 ingredienti extra in ogni prodotto, i clienti adorano personalizzare i loro ordini"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                            color: fCTitle
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <HighlightOffIcon  />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="Nessuna Offerta Speciale :(" 
                        secondary="Attira clienti mettendo in risalto dei prodotti a prezzo vantaggioso"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <HighlightOffIcon  />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="Nessuna Immagine per i prodotti :(" 
                        secondary="Scegli un bel titolo, perchè non potrai aggiungere immagini"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="Logo personalizzato" 
                        secondary="Inserisci il logo della tua azienda"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            color: fCTitle,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <HighlightOffIcon />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="0 Immagini del locale disponibili :(" 
                        secondary="Puoi inserire fino a 2 immagini nella slideshow della descrizione del tuo locale"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <HighlightOffIcon />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="Revisione commenti" 
                        secondary="Un algoritmo verificherà la validità dei commenti dei tuoi clienti per evidenziare eventuali problemi"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <HighlightOffIcon />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="Visibilità durante il servizio" 
                        secondary="Sull'app dei clienti la card del tuo locale sarà disponibile durante tutta la durata del servizio"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="Supporto Via Mail" 
                        secondary="Puoi richiedere aiuto e supporto via mail, lo staff risponderà entro 24 ore"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                            color: fCTitle
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <HighlightOffIcon />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="Nessun Supporto Telefonico :(" 
                        secondary="Per contattare il supporto dovrai scrivere una mail, sarai contattato dallo staff"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <HighlightOffIcon />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="Nessun Intervento in loco" 
                        secondary="Per contattare il supporto dovrai scrivere una mail, sarai contattato dallo staff"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>
           </List>
           
           <br />
            <span className={classes.card_price}>
                COMPLETAMENTE GRATIS
            </span>
            
        </div>

        <div className={classes.card}>
            <span className={classes.card_title}>
                SPECIAL
            </span>
            <hr />
            <List>
                <ListItem>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon sx={{ color: 'white' }}  />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="50 Prodotti" 
                        secondary="Puoi aggiungere fino a 20 prodotti da far comprare ai tuoi clienti"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            color: fCTitle,
                            fontFamily: fF,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                    <CheckCircleOutlineIcon sx={{ color: 'white' }}  />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="6 Ingredienti Extra" 
                        secondary="Puoi aggiungere 6 ingredienti extra in ogni prodotto, garantisci una buona scelta di personalizzazione ai clienti"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            color: fCTitle,
                            fontFamily: fF,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="3 Offerte Speciali " 
                        secondary="Attira clienti mettendo in risalto fino a 3 prodotti a prezzo vantaggioso"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            color: fCTitle,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>
                
                <ListItem>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="3 Immagini prodotto" 
                        secondary="Scegli i tuoi 3 migliori prodotti, e inserisci la loro immagine"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            color: fCTitle,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="Logo personalizzato" 
                        secondary="Inserisci il logo della tua azienda"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            color: fCTitle,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="2 Immagini del locale" 
                        secondary="Puoi inserire fino a 2 immagini nella slideshow della descrizione del tuo locale"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            color: fCTitle,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="Revisione commenti" 
                        secondary="Chiedi al supporto di verificare direttamente la validità di uno specifico commento, fino a 5 volte al mese."
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            color: fCTitle,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="Sempre visibile" 
                        secondary="La card della tua azienda sarà disponibile ai clienti sempre: 24/7"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            color: fCTitle,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="Supporto rapido Via Mail" 
                        secondary="Puoi richiedere aiuto e supporto via mail, lo staff risponderà entro 12 ore"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                            color: fCTitle
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="Supporto Telefonico" 
                        secondary="Puoi chiamare il supporto telefonico dal Martedì alla Domenica dalle 19.00 alle 24.00"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                            color: fCTitle
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <HighlightOffIcon />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="Nessun Intervento in loco" 
                        secondary="Per contattare il supporto dovrai scrivere una mail, sarai contattato dallo staff"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>
           </List>
            
           <div className={classes.boxShop}>
                <div>
                    <span className={classes.card_price}>
                        Costo Mensile
                    </span>
                    <br />
                    <span className={classes.card_price}>
                        14.99€
                    </span>
                    <br />
                    <button onClick={() => handlePlan(1)} className={classes.btn}>RICHIEDI SUBITO!</button>
                </div>
                
                <div>
                    <span className={classes.card_price}>
                        Costo Annuale
                    </span>
                    <br />
                    <span className={classes.card_price}>
                        <s>179.88€</s> <b>143.90€</b>
                    </span>
                    <br />
                    <button onClick={() => handlePlan(2)} className={classes.btn}>RICHIEDI SUBITO!</button>
                </div>
           </div>

        </div>

        <div className={classes.card}>
        <span className={classes.card_title}>
                PREMIUM
            </span>
            <hr />
            <List>
                <ListItem>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="Prodotti ILLIMITATI" 
                        secondary="Aggiungi tutti i prodotti che vuoi vendere ai tuoi clienti"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            color: fCTitle,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="50 Ingredienti Extra" 
                        secondary="Puoi aggiungere praticamente qualsiasi ingrediente extra ti venga in mente su ogni prodotto"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            color: fCTitle,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="5 Offerte Speciali " 
                        secondary="Attira clienti mettendo in risalto fino a 5 prodotti a prezzo vantaggioso"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            color: fCTitle,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="5 Immagini prodotto" 
                        secondary="Crea una piccola vetrina foto con i tuoi 5 migliori prodotti"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            color: fCTitle,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="Logo personalizzato" 
                        secondary="Inserisci il logo della tua azienda"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            color: fCTitle,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="5 Immagini del locale + Logo" 
                        secondary="Puoi inserire un logo e fino a 5 immagini nella slideshow della descrizione del tuo locale"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            color: fCTitle,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="Revisione commenti" 
                        secondary="Chiedi al supporto di verificare direttamente la validità di uno specifico commento, fino a 15 volte al mese."
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            color: fCTitle,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="Sempre visibile" 
                        secondary="La card della tua azienda sarà disponibile ai clienti sempre: 24/7"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            color: fCTitle,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="Supporto rapido Via Mail" 
                        secondary="Puoi richiedere aiuto e supporto via mail, lo staff risponderà entro 12 ore"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                            color: fCTitle
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="Supporto Telefonico" 
                        secondary="Puoi chiamare il supporto telefonico dal Martedì alla Domenica dalle 19.00 alle 24.00"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                            color: fCTitle
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText 
                        className={classes.ListItemText} 
                        primary="1 Supporto In Loco" 
                        secondary="Puoi richiedere un supporto in loco 1 volta al mese concordando l' appuntamento col tecnico"
                        primaryTypographyProps={{
                            fontSize: fSTitle,
                            fontFamily: fF,
                            fontWeight: fWTitle,
                            lineHeight: lHTitle,
                            color: fCTitle
                        }}
                        secondaryTypographyProps={{
                            fontSize: fSSubTitle,
                            fontFamily: fF,
                            fontWeight: fWSubTitle,
                            lineHeight: lHSubTitle,
                        }}
                    />                    
                </ListItem>
           </List>
           
           <div className={classes.boxShop}>
                <div>
                    <span className={classes.card_price}>
                        Costo Mensile
                    </span>
                    <br />
                    <span className={classes.card_price}>
                        14.99€
                    </span>
                    <br />
                    <button onClick={() => handlePlan(3)} className={classes.btn}>RICHIEDI SUBITO!</button>
                </div>
                
                <div>
                    <span className={classes.card_price}>
                        Costo Annuale
                    </span>
                    <br />
                    <span className={classes.card_price}>
                        <s>179.88€</s> <b>143.90€</b>
                    </span>
                    <br />
                    <button onClick={() => handlePlan(4)} className={classes.btn}>RICHIEDI SUBITO!</button>
                </div>
           </div>
        </div>
    </div>;
}
  
  export default PlanCards;
  