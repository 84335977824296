import { configureStore } from '@reduxjs/toolkit';

//Import Slices
import service from './service';
import orders from './orders'

const store = configureStore({
    reducer: {
        service: service.reducer,
        orders: orders.reducer
    }
})

export default store;