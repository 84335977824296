import Element, { ElementTemplate } from "./ListElement";
import { useDispatch, useSelector } from "react-redux";
import classes from './List.module.css';
import { useEffect, useState } from "react";
import OrderProductsDetail from "./modal/OrderProductsDetail";
import ManageOrderPopup from "./modal/ManageOrderPopup";
import OrderAssistance from "./modal/OrderAssistance";

function List (props) {    
    const [filteredOrders, setFilteredOrders] = useState([]);
    const orders = useSelector(state => state.orders.orders);
    const [showDetail, setShowDetail] = useState(false)
    const [modalInformation, setModalInformation] = useState({});
    //Gestione hide/show
    const [isShow, setIsShow] = useState(true);
    //Accetta modal
    const [showManage, setShowManage] = useState(false)
    const [id, setId] = useState();
    const [status, setStatus] = useState();
    //Assistance Modal
    const [help, setHelp] = useState(false)
    const [assId, setAssId] = useState();
    
    const showProductsDetailModal = () => {
        setShowDetail(true)
    }

    const hideProductsDetailModal = () => {
        setShowDetail(false)
    }

    const showManageModal = (id, status) => {
        setId(id)
        setStatus(status)
        setShowManage(true)
    }

    const hideManageModal = () => {
        setId(0)
        setStatus(-2)
        setShowManage(false)
    }

    const retriveInfo = (info) => {
        setModalInformation(info)
    }

    const handleAssistance = (id) => {
        setAssId(id)
        setHelp(true)
    }

    const handleContatta = (id) => {
        const newWindowUrl = 'http://localhost:3001/help';
        const windowFeatures = 'width=600,height=400';
        window.open(newWindowUrl, windowFeatures);
    }

    const handleContesta = () => {
        const newWindowUrl = 'http://localhost:3001/help';
        const windowFeatures = 'width=600,height=400';
        window.open(newWindowUrl, windowFeatures); 
    }

    const closeAssistance = () => {
        setHelp(false)
    }

    const handleShow = () => {
        if (isShow) {
            setIsShow(false)
        }
        else {
            setIsShow(true)
        }
    }

    const handleUpdate = () => {
        props.updateOrders()
    }
    
    useEffect(()=>{
        if (orders) {
            console.log("Qui", orders[0])
            const oggettiFiltrati = orders.filter(oggetto => oggetto.status === props.type);
            setFilteredOrders(oggettiFiltrati)
        }
    }, [orders])

    useEffect(() => {
        if (props.type === 5) {
          setIsShow(false);
        }
    }, [props.type]);

    if (filteredOrders)
        if (filteredOrders.length > 0)
            return (
                <>
                    <div className={classes.topBar}> 
                        <span className={classes.title}>{props.title}:</span>
                        {isShow
                            ? <span className={classes.title}><button className={classes.btn} onClick={handleShow}>Nascondi "{props.title}"</button></span>
                            : <span className={classes.title}><button className={classes.btn} onClick={handleShow}>Mostra "{props.title}"</button></span>
                        }
                    </div>
                    {   isShow
                        ? <div className={classes.table}>
                            <ElementTemplate />
                            { filteredOrders
                                .map(( order =>
                                    <Element
                                        key={order._id}
                                        items={order}
                                        showProductsDetailModal={showProductsDetailModal} 
                                        showManageModal={showManageModal}
                                        retriveInfo={retriveInfo}
                                        handleAssistance={handleAssistance}
                                        closeAssistance={closeAssistance}                                    
                                        type={props.type}
                                    />
                                ))
                            }
                        </div>
                        : <div className={classes.hidden}></div>
                    }

                    { showDetail && <OrderProductsDetail hideModal={hideProductsDetailModal} info={modalInformation} />}
                    { showManage && <ManageOrderPopup hideModal={hideManageModal} info={modalInformation} status={status} updateOrders={props.updateOrders} />}
                    { help && <OrderAssistance hideModal={closeAssistance} handleContatta={handleContatta} handleContesta={handleContesta} />}
                </>
            );
    else 
        if (props.type == 0)
            return (
                <div className={classes.noOrder}>
                    <span className={classes.title}>Nessun nuovo ordine... per ora.</span>
                </div> 
            );
        else
            return null;
    
}

export default List;