import { useEffect, useState } from "react";
import { getSummary } from "../helper/summaryLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faStarHalfStroke } from "@fortawesome/free-solid-svg-icons";
import LoadingScreen from "./navigation/LoadingScreen";

import classes from './CompanySummary.module.css';

function Summary () {
    const [summary, setSummary] = useState({orderCount : 0, voto : 0, reviews: [], mediaAmount: 0})
    const [isLoading, setIsLoading] = useState(false)
    const [countdown, setCountdown] = useState(600);

    const call = async () => {
        setIsLoading(true)
        const response = await getSummary();
        setSummary(response.data);
        setCountdown((prevCountdown) => response.data.timeToService)
        setIsLoading(false)
    }

    const formatTime = (seconds) => {
      if (seconds === -1) {
        return 'Oggi festivo - nessun servizio previsto';
      }

    
      if (seconds < -1) {
        return <p style={{ color: 'red' }}> Sei in ritardo per il servizio !</p>;
      }
    
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
    
      return `Prossimo Servizio tra: ${hours > 0 ? `${hours} ore ` : ''}${minutes} minuti ${remainingSeconds} secondi`;
    }; 

    function renderStars(score) {
        const stars = [];
        const fullStars = Math.floor(score);
        const hasHalfStar = score % 1 === 0.5;
        const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);
      
        for (let i = 0; i < fullStars; i++) {
          stars.push(<FontAwesomeIcon icon={faStar} style={{color: "#ffdd00"}} key={`full${i}`} beatFade />); 
        }
      
        if (hasHalfStar) {
          stars.push(<FontAwesomeIcon icon={faStarHalfStroke} style={{color: "#ffdd00"}} beatFade key="half" /> );
        }
      
        for (let i = 0; i < emptyStars; i++) {
          stars.push(<FontAwesomeIcon icon={faStar} color="gray" opacity={0.4} key={`void${i}`} />);
        }
      
        return stars;
    }

    useEffect(()=>{
        call();
    }, [])

    useEffect(()=>{
      if (countdown > 0) {
        const interval = setInterval(() => {
          setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);
    
        return () => clearInterval(interval);
      }
    }, [countdown])

    return (
        <div className={classes.box}>
            { isLoading
              ? <LoadingScreen />
              : null
            }
            <h3>DASHBOARD</h3>
            <h4>{formatTime(countdown)}</h4>
            <h4>Totale ordini {summary.ordersCount} </h4>
            <h4>Totale recensioni {summary.reviews.length || 0}</h4>
            <h4>Media valutazione dagli utenti: {summary.voto} {renderStars(summary.voto) || 0}</h4>
            <h4>Importo medio degli ordini: €{summary.mediaAmount ?summary.mediaAmount.toFixed(2) :0} </h4>           
        </div>
    )
};

export default Summary;