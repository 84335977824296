import { useState } from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import classes from './Setup.module.css';
import { callPatch, callPost } from '../api/PuorkServer';

function Setup() {
    const [page, setPage] = useState(0);
    const [drivers, setDrivers] = useState(0);
    const [intervallo, setIntervallo] = useState(0);
    const [provincia, setProvincia] = useState("Napoli");
    const [indirizzo, setIndirizzo] = useState("");
    const [civico, setCivico] = useState();
    const [note, setNote] = useState("");
    const [comune, setComune] = useState("");

    //** VARIABILI DELLA SPEDIZIONE **/
    const [free, setFree] = useState(false);
    const [fixed, setFixed] = useState(false);
    const [variable, setVariable] = useState(false);
    const [fixedCost, setFixedCost] = useState('');
    const [variablelimit1, setVariablelimit1] = useState('');
    const [variablelimit2, setVariablelimit2] = useState('');
    const [variableCost1, setVariableCost1] = useState('');
    const [variableCost2, setVariableCost2] = useState('');
    const [variableLimitFree, setVariableLimitFree] = useState('');
    //** FINE VAR. DELLA SPEDIZIONE**/

    //** VARIABILI DEL CALENDARIO SETTIMANALE **/
    const week = [
        "Lunedì",
        "Mertedì",
        "Mercoledì",
        "Giovedì",
        "Venerdì",
        "Sabato",
        "Domenica"
    ]
    const rows = [], headers = [];
    const FrazioniGiorno = [
        "Apertura Mattina",
        "Chiusura Mattina",
        "Apertura Sera",
        "Chiusura Sera",
        "Giorno Festivo"
    ]

    const [orariLavorativi, setOrariLavorativi] = useState([
        {
            aperturaMattina: 0,
            chiusuraMattina: 0,
            aperturaSera: 0,
            chiusuraSera: 0,
            festivo: 0,
        },
        {
            aperturaMattina: 0,
            chiusuraMattina: 0,
            aperturaSera: 0,
            chiusuraSera: 0,
            festivo: 0,
        },
        {
            aperturaMattina: 0,
            chiusuraMattina: 0,
            aperturaSera: 0,
            chiusuraSera: 0,
            festivo: 0,
        },
        {
            aperturaMattina: 0,
            chiusuraMattina: 0,
            aperturaSera: 0,
            chiusuraSera: 0,
            festivo: 0,
        },
        {
            aperturaMattina: 0,
            chiusuraMattina: 0,
            aperturaSera: 0,
            chiusuraSera: 0,
            festivo: 0,
        },
        {
            aperturaMattina: 0,
            chiusuraMattina: 0,
            aperturaSera: 0,
            chiusuraSera: 0,
            festivo: 0,
        },
        {
            aperturaMattina: 0,
            chiusuraMattina: 0,
            aperturaSera: 0,
            chiusuraSera: 0,
            festivo: 0,
        },
    ]);
    //** FINE VAR. CALENDARIO SETTIMANALE **/

    const handleSpedizCheckboxChange = (event) => {
        const { name, checked } = event.target;
        if (name === 'free') {
            setFree(checked);
            setFixed(false);
            setVariable(false);
        } else if (name === 'fixed') {
            setFree(false);
            setFixed(checked);
            setVariable(false);
        } else if ( name === 'variable') {
            setFree(false);
            setFixed(false);
            setVariable(checked);
        }
    };

    const handleAvanti = async () => {
        switch (page){
            case 0:
                setPage(page + 1)
                return;
            case 1:
                if (indirizzo == "")
                    alert ("devi inserire un indirizzo valido")
                else if (comune == "")
                    alert ("devi inserire un comune valido")
                else if (civico == "")
                    alert ("devi inserire un civico valido")
                else
                    setPage(page + 1)
                return;
            case 2:
                if (drivers == 0){
                    alert("devi inserire almeno un driver!")
                } else if (intervallo == 0) {
                    alert("devi scegliere un intervallo di consegna!")
                } else 
                    setPage(page + 1)
                return;
            case 3:
                if (free || fixed || variable )                
                    setPage(page + 1)
                else 
                    alert("Fai una scelta")
                break;
            case 4:
                if (free) {
                    await callPost('/company/address/add', {provincia, indirizzo, comune, note, civico})
                    await callPatch('/company/service/update', {orari: orariLavorativi, fasce_consegna: intervallo, driverAmount: drivers, free : true});
                    return window.location.href = '/';
                     
                }

                if (fixed){
                    await callPost('/company/address/add', {provincia, indirizzo, comune, note, civico})
                    await callPatch('/company/service/update', {orari: orariLavorativi, fasce_consegna: intervallo, driverAmount: drivers, fixed: true, fixedCost});
                    return window.location.href = '/';
                }

                if (variable){
                    const variableObj = {
                        var1 : {
                            limit : variablelimit1,
                            cost : variableCost1
                        },
                        var2 : {
                            limit : variablelimit2,
                            cost : variableCost2
                        },
                        free : variableLimitFree
                    }

                    await callPost('/company/address/add', {provincia, indirizzo, comune, note, civico})
                    await callPatch('/company/service/update', {orari: orariLavorativi, fasce_consegna: intervallo, driverAmount: drivers, variable: true, variableObj});
                    return window.location.href = '/';
                }                
        }
        
    }
    
    const handleIndietro = () => {
        if (page > 0)
            if (window.confirm('Vuoi tornare indietro e resetti questo passaggio?')) setPage(page - 1)
            else return;
    }

    const handleOption = (event) => {
        setIntervallo(event.target.value);
    }

    const handleInputChange = (event) => {
        const value = event.target.value;

        // Rimuovi tutti i caratteri non numerici
        const sanitizedValue = value.replace(/[^0-9]/g, '');

        setDrivers(sanitizedValue);
    }; 

    const handleInputChangeOrari = (index, tipoOrario, event) => {
        const value = parseInt(event.target.value, 10);
        
        // Assicurati che il valore sia compreso tra 0 e 24
        const orarioValido = Math.max(0, Math.min(value, 24));
    
        // Aggiorna lo stato con i nuovi orari utilizzando la funzione di callback
        setOrariLavorativi((prevOrari) => {
            const nuovoOrarioLavorativo = [...prevOrari];  // Copia l'array originale
    
            nuovoOrarioLavorativo[index] = {
                ...nuovoOrarioLavorativo[index],
                [tipoOrario]: orarioValido,
            };
    
            return nuovoOrarioLavorativo;
        });
    };

    //** PREPARAZIONE DEL CALENDARIO **/
    const handleCheckboxChange = (index) => {
        setOrariLavorativi((prevOrari) => {
            const nuovoOrarioLavorativo = [...prevOrari];  // Copia l'array originale
            const giorno = week[index].toLowerCase();
            
            nuovoOrarioLavorativo[index] = {
                ...nuovoOrarioLavorativo[index],
                festivo: !nuovoOrarioLavorativo[index]?.festivo,
            };
    
            return nuovoOrarioLavorativo;
        });
    };

    headers.push(<div className={classes.cellHeader} key={-1}>SCEGLI</div>); 
    for (let i=0; i < 7; i++) {
        headers.push(<div className={classes.header} key={i}>
            {week[i]}
        </div>);  
    }
    rows.push(<div className={classes.row} key="900">{headers}</div>);
    for (let i=0; i < 5; i++) {
        const cells = []
        cells.push(<div className={classes.cellHeader} key={900+i}>
            {FrazioniGiorno[i]}
        </div>);

        for (let j=0; j<7; j++){
            if (i==4) {
                cells.push(<div className={classes.header} key={j*(1+i)}>
                    <input 
                        id={"f"+i+"d"+j} 
                        name={"f"+i+week[j]} 
                        type="checkbox"
                        onChange={() => handleCheckboxChange((j))}
                    />
                </div>);
            } else {
                cells.push(<div className={classes.header} key={j*(1+i)}>
                    {i < 2
                        ? i == 0
                            ? <input
                                id={"f"+i+"d"+j}
                                name={"f"+i+week[j]}
                                type="number"
                                step={1} min={0} max={17}
                                placeholder='Inserisci' 
                                onFocus={(event) => event.target.value = ""}
                                value={parseInt(orariLavorativi[j]?.aperturaMattina ?? 0, 10)}
                                onChange={(event) => handleInputChangeOrari(j, "aperturaMattina", event)}
                            />
                            : <input
                                id={"f"+i+"d"+j}
                                name={"f"+i+week[j]}
                                type="number"
                                step={1} min={0} max={17}
                                placeholder='Inserisci'
                                value={orariLavorativi[j]?.chiusuraMattina ?? 0}
                                onChange={(event) => handleInputChangeOrari(j, "chiusuraMattina", event)}

                            />
                        : i == 2
                            ? <input
                                id={"f"+i+"d"+j}
                                name={"f"+i+week[j]}
                                type="number"
                                step={1} min={17} max={24}
                                placeholder='Inserisci'
                                value={orariLavorativi[j]?.aperturaSera ?? 0}
                                onChange={(event) => handleInputChangeOrari(j, "aperturaSera", event)}
                            />
                            : <input
                                id={"f"+i+"d"+j}
                                name={"f"+i+week[j]}
                                type="number"
                                step={1} min={17} max={24}
                                placeholder='Inserisci'
                                value={orariLavorativi[j]?.chiusuraSera ?? 0}
                                onChange={(event) => handleInputChangeOrari(j, "chiusuraSera", event)}
                            />
                    }
            </div>);
            }
            
        }

        rows.push( <div className={classes.row} key={i}>
            {cells}
        </div>);
    }
    //** FINE PREPARAZIONE DEL CALENDARIO **/
    const whatPage = () => {
        switch (page) {
            case 0: 
                return (<div className={classes.box}>
                    <p className={classes.title}>Benvenuto nel tuo primo login su PUORK!</p>
                    <p className={classes.subTitle}>
                        Ti aiuteremo a preparare la tua azienda per il grande pubblico! segui i passaggi e non lasciare nulla al caso.
                    </p>
                    <Button variant="primary" onClick={handleAvanti}>Iniziamo subito!</Button>
                </div>)
            case 1:
                return ( <div className={classes.box} >
                    <p className={classes.miniTitle}>Inserisci l'indirizzo della tua azienda</p>
                    <InputGroup className="mb-3">
                        <InputGroup.Text className={classes.inputLabel} id="basic-addon1">Provincia di</InputGroup.Text>
                        <Form.Control
                            type="text"
                            aria-describedby="basic-addon1"
                            value={provincia}
                            className="custom-disabled-input"
                        /> 
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text className={classes.inputLabel} id="basic-addon1">Comune</InputGroup.Text>
                        <Form.Control
                            type="text" 
                            placeholder="Inserisci il comune"
                            aria-label="comune"
                            aria-describedby="basic-addon1"
                            value={comune}
                            onChange={(event) => setComune(event.target.value)}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text className={classes.inputLabel} id="basic-addon1">Indirizzo</InputGroup.Text>
                        <Form.Control
                            type="text" 
                            aria-describedby="basic-addon1"
                            placeholder="Inserisci un indirizzo"
                            value={indirizzo}
                            onChange={(event) => setIndirizzo(event.target.value)}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text className={classes.inputLabel} id="basic-addon1">Civico</InputGroup.Text>
                        <Form.Control
                            type="text"
                            aria-describedby="basic-addon1"
                            value={civico}
                            placeholder="Inserisci il civico"
                            onChange={(event) => setCivico(event.target.value)}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text className={classes.inputLabel} id="basic-addon1">Note</InputGroup.Text>
                        <Form.Control
                            type="text" 
                            aria-describedby="basic-addon1"
                            value={note}
                            placeholder="Inserisci delle note se occorre"
                            onChange={(event) => setNote(event.target.value)}
                        />
                    </InputGroup>
                </div>);
            case 2:
                return <div className={classes.box}>
                    <p className={classes.miniTitle}>Quanti driver hai a disposizione per inviare contemporaneamente gli ordini ai tuoi clienti?</p>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">Inserisci un numero</InputGroup.Text>
                        <Form.Control
                            type="text" // Usiamo il tipo "text" per consentire solo la manipolazione di numeri nel gestore onChange
                            placeholder=""
                            aria-label="numero"
                            aria-describedby="basic-addon1"
                            value={drivers}
                            onChange={handleInputChange}
                        />
                    </InputGroup>
                    <hr></hr>
                    <p className={classes.miniTitle}>Quanto intervallo di tempo desideri tra una spedizione ed un'altra?</p>
                        <Form.Select onChange={handleOption} aria-label="Default select example">
                            <option>Scegli un valore</option>
                            <option value="10">Una spedizione ogni 10 minuti</option>
                            <option value="15">Una spedizione ogni 15 minuti</option>
                            <option value="20">Una spedizione ogni 20 minuti</option>
                            <option value="30">Una spedizione ogni 30 minuti</option>
                        </Form.Select>
                </div>
            case 3:
                return ( <div className={classes.box}>
                    <p className={classes.miniTitle}>
                        Scegli come gestire i costi delle spedizioni dei tuoi ordini.
                    </p>
                    <input
                        type="checkbox"
                        name="free"
                        checked={free}
                        onChange={handleSpedizCheckboxChange}
                    />
                    Spedizione gratis
                    <br />
                    <input
                        type="checkbox"
                        name="fixed"
                        checked={fixed}
                        onChange={handleSpedizCheckboxChange}
                    />
                    Costo fisso
                    <br />
                        <input
                        type="checkbox"
                        name="variable"
                        checked={variable}
                        onChange={handleSpedizCheckboxChange}
                    />
                    Costo Variabile
                    <br />
            
                    {   fixed && (
                        <input
                            title="Ogni ordine avrà lo stesso importo per la spedizione (costo minimo di 1€ massimo di 10€)"
                            type="number"
                            placeholder="Inserisci il costo di spedizione fisso"
                            value={fixedCost}
                            name="fixedCost"
                            min={1}
                            max={10}
                            onChange={(e) => setFixedCost(e.target.value)}
                        />
                    )}

                    { variable && (
                        <div className={classes.spedizione}>
                            <p><h4>In base al totale dell'ordine l'utente avrà un costo dinamico di spedizione: </h4>
                                <ul>
                                    <li>Fino a "Primo Limite" avrà "Primo Prezzo" come costo.</li>
                                    <li>Da "Primo Limite" a "Secondo Limite" avrà "Secondo Prezzo" come costo</li>
                                    <li>Con ordini maggiore di "Limite Gratis" il cliente potrà avere la spedizione gratis</li>
                                </ul>
                                <b>Nota Bene:</b> Il primo limite deve essere inferiore al secondo ed il terzo, inoltre il secondo limite deve essere inferiore o uguale al terzo. Infine se desideri non applicare una spedizione gratuita ti basterà semplicemente inserire "9999" nel "Limite Gratis"
                            </p>
                            <input
                                type="number"
                                title="Somma di totale nella quale rientra la prima fascia di prezzo"
                                placeholder="Primo Limite"
                                value={variablelimit1}
                                name="firstLimit"
                                onChange={(e) => setVariablelimit1(e.target.value)}
                            />
                            <input
                                type="number"
                                placeholder="Primo Prezzo"
                                value={variableCost1}
                                name="firstCost"
                                onChange={(e) => setVariableCost1(e.target.value)}
                            />
                            <br />
                            <input
                                type="number"
                                title="Somma di totale nella quale rientra la seconda fascia di prezzo"
                                placeholder="Secondo Limite"
                                value={variablelimit2}
                                name="secondLimit"
                                onChange={(e) => setVariablelimit2(e.target.value)}
                            />
                            <input
                                type="number"
                                placeholder="Secondo Prezzo"
                                value={variableCost2}
                                name="secondCost"
                                onChange={(e) => setVariableCost2(e.target.value)}
                            />
                            <br />
                            <input
                                type="number"
                                title="Somma di totale da superare per un ordine gratis"
                                placeholder="Limite Gratis"
                                value={variableLimitFree}
                                name="freeLimit"
                                onChange={(e) => setVariableLimitFree(e.target.value)}
                            />
                        </div>
                    )}
                </div>);
            case 4:
                return <div className={classes.box}>
                    <p className={classes.miniTitle}>Scegli gli orari lavorativi della settimana, per i giorni festivi utilizza la checkbox in fondo </p>
                    {rows}
                </div>                
        }
    }

    return ( 
        <div className={classes.body}>            
            <div className={classes.container}> 
                {whatPage()} 
                { page == 0
                    ? null
                    : <div className={classes.btnBox}><button className={classes.btn} onClick={handleIndietro}>Indietro</button> <button className={classes.btn} onClick={handleAvanti}>Avanti</button> </div>
                }
            </div>
        </div>
    );
  }
  
  export default Setup;
  