import callApi from "../api/PuorkServer";

export async function getOrders () {
    const response = await callApi('/company/orders', "GET");
    return response;
}

export async function getOrder (orderN) {
    const response = await callApi('/company/order', "GET", {number : orderN});
    return response;
}

export async function setOrderStatus (id, status) {
    const response = await callApi('/order/status', "POST", {id , status });
    return response;
}

export async function acceptOrder (id, orario, driver, fascia) {
    const response = await callApi('/order/accept', "POST", {id : id, orario : orario, driver, fascia});
    return response;
}

export async function refuseOrder (id, motivo) {
    const response = await callApi('/order/refuse', "POST", {id : id, motivo : motivo});
    return response;
}