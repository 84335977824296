import { Outlet } from "react-router-dom";
import SubTopBarNavigation from "../component/navigation/SubTopBarNavigation";

function SubRootLayout() {
    return (
        <>
            <SubTopBarNavigation />
            <main style={{textAlign: "center"}}>
                <Outlet />
            </main>
        </>
    );
}

export default SubRootLayout;