import { useEffect, useState } from 'react';
import Modal from "../../../ui/Modal";
import classes from "./OrderProductsDetail.module.css";
import { getOrder } from "../../../api/OrdersApi";

function OrderProductsDetail ({hideModal, info}) {
    const calcTot = (index) => {
        let totale = info.prodotti[index].prezzo
        info.prodotti[index].aggiuntivi.map((prodotto) => {
            totale += prodotto.costo
        })
        return totale
    }

    return (
        <Modal onClose={hideModal}>
            <span className={classes.title}>Dettaglio Scontrino</span>    

            <div className={classes.tab}>
                <div className={classes.row}>
                    <div className={classes.col } style={{ width: '25vh' }}> Nome Prodotto</div>
                    <div className={classes.col } style={{ width: '41vh' }}> Ingredienti Aggiuntivi</div>
                    <div className={classes.col } style={{ width: '20vh' }}> Prezzo </div>
                </div>
                { 
                    info.prodotti
                        .filter(prod => prod.descrizione !== "spedizione")
                        .map((prodotto, idx) => {
                            return (
                                <div className={classes.row} key={idx}>
                                    <div className={classes.col } style={{ width: '25vh' }}> {prodotto.nome + " (" + prodotto.prezzo + "€) "} </div>                                            
                                    { prodotto.aggiuntivi.length > 0
                                        ? <div className={classes.col } style={{ width: '41vh'}}>
                                            {
                                                prodotto.aggiuntivi.map((ingrediente)=>{
                                                    return " (" + ingrediente.nome + " - " + ingrediente.costo + "€) "
                                                })
                                            }
                                        </div>
                                        : <div className={classes.col } style={{ width: '41vh'}}> - </div>
                                    }
                                    <div className={classes.col } style={{ width: '20vh' }}> 
                                        {
                                            calcTot(idx)
                                        }
                                        € 
                                    </div>
                                </div>
                            );
                    })
                }
            </div>
            <h5>Costo Spedizione: {info.prodotti.find(prod => prod.descrizione === "spedizione")?.prezzo}€</h5>
            <span className={classes.title}>Totale : {info.totale}€</span>    
            <br />
            <button className={classes.btn} onClick={() => hideModal()}> CHIUDI </button>
        </Modal>
    );
}

export function OrderProductSelfDetail ({hideModal, orderN}) {
    const [order, setOrder] = useState();

    const call = async () => {
        const response = await getOrder(orderN);
        setOrder(response.data)
    }

    useEffect(()=>{
        call();
    }, [])

    const calcTot = (index) => {
        let totale = order.prodotti[index].prezzo
        order.prodotti[index].aggiuntivi.map((prodotto) => {
            totale += prodotto.costo
        })
        return totale
    }

    if (order == null)
     return;

    return (
        <Modal onClose={hideModal}>
        <span className={classes.title}>Dettaglio Scontrino</span>
        <h4>Indirizzo: {order.indirizzo}</h4>

        <div className={classes.tab}>
            <div className={classes.row}>
                <div className={classes.col } style={{ width: '25vh' }}> Nome Prodotto</div>
                <div className={classes.col } style={{ width: '41vh' }}> Ingredienti Aggiuntivi </div>
                <div className={classes.col } style={{ width: '20vh' }}> Prezzo  </div>
            </div>
            { order.prodotti.map((prodotto, idx) => {
                return (
                    <div className={classes.row}>
                        <div className={classes.col } style={{ width: '25vh' }}> {prodotto.nome + " (" + prodotto.prezzo + "€) "} </div>                                            
                        { prodotto.aggiuntivi.length > 0
                            ? <div className={classes.col } style={{ width: '41vh'}}>
                                {
                                    prodotto.aggiuntivi.map((ingrediente)=>{
                                        return " (" + ingrediente.nome + " - " + ingrediente.costo + "€) "
                                    })
                                }
                            </div>
                            : <div className={classes.col } style={{ width: '41vh'}}> - </div>
                        }
                        <div className={classes.col } style={{ width: '20vh' }}> 
                            {
                                calcTot(idx)
                            }
                            € 
                        </div>
                    </div>
                );
            })}
        </div>
        
        <span className={classes.title}>Totale : {order.totale}€</span>
        <br />
        <button className={classes.btn} onClick={() => hideModal()}> CHIUDI </button>
        </Modal>
    );
}

export default OrderProductsDetail