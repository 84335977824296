import { useState } from "react";

import {SubModal} from "../../../ui/Modal";
import { refuseOrder } from '../../../api/OrdersApi';

import classes from "./RifiutaModal.module.css";

function RifiutaModal ({id, hideModal}) {   
    const [motivo, setMotivo] = useState("");

    const handleMotivoChange = (e) => {
        console.log(e.target.value)
        setMotivo(e.target.value);
    }

    const handleFormSubmit = async (hideModal) => {
        console.log("we")
        if (motivo == "")
            return;
      
        console.log("usec")
        await refuseOrder(id, motivo);
        hideModal();
    }

    return (
        <SubModal onClose={hideModal}>
            <div className={classes.main}>
                <span className={classes.title}>Perchè vuoi rifiutare l'ordine?</span>                 
                <select className={classes.select} id="motivo" name="motivo" onChange={handleMotivoChange}>                        
                    <option value="">Scegli</option>
                    <option value="Troppa Affluenza di clienti">Troppa Affluenza di clienti</option>
                    <option value="Problemi tecnici">Mancanza di ingredienti principali</option>
                    <option value="Altro / Non specificato">Altro / Non specificato</option>
                </select>
                <span className={classes.msg}>Attenzione, lo STAFF di Puork ti ricorda che <b>rifiuti continui di ordini possono ledere alla tua azienda!</b> Cerca di fare il possibile per accontentare tutti i clienti curiosi di assaggiare le tue pietanze.</span>
                <button className={classes.btn} onClick={() => handleFormSubmit(hideModal)}> PROCEDI CON IL RIFIUTO </button>
                <button className={classes.btn} onClick={() => hideModal()}> ANNULLA </button>
            </div>
        </SubModal>
    );
}

export default RifiutaModal