import { useState } from "react";
import { Form, useNavigation } from "react-router-dom";

import classes from "./FormAddProduct.module.css";
import LoadingScreen from "../navigation/LoadingScreen";

export default ({categories}) => {
    const [row, setRow] = useState([]);
    const [numRow, setNumRow] = useState(0);
    const navigation = useNavigation();
    const isSaving = navigation.state === 'submitting';
    
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const maxSizeInBytes = 150 * 1024; // 150kb
            const maxWidth = 350;
            const maxHeight = 180;

            if (!file.type.startsWith("image/")) {
                alert("Scegli un file immagine (JPG o PNG).");
                return;
            }
    
            if (file.size > maxSizeInBytes) {
                alert("L'immagine è troppo grande. Scegli un file più piccolo.");
                return;
            }
            
            const reader = new FileReader();
            reader.onloadend = () => {
                const img = new Image();
                img.src = reader.result;

                img.onload = () => {
                    if (img.width > maxWidth || img.height > maxHeight) {
                        alert(`L'immagine deve essere al massimo ${maxWidth}px x ${maxHeight}px.`);
                    } else {
                        document.getElementById("imageH").value = reader.result;
                    }
                };
        };
        }
    };

    return (<Form method="post" className={classes.form}>
        {isSaving ? <LoadingScreen /> : null}
        <h1>Aggiungi prodotto</h1>

        <div className={classes.bodyPrd}>
            <h3>Informazioni </h3>

            <p >
                <label htmlFor="nomeProdotto">Nome prodotto</label>
                <input id="nomeProdotto" name="nomeProdotto" required minLength={4}/>
            </p>

            <p>
                <label htmlFor="descrizioneProdotto">Descrizione</label>
                <input id="descrizioneProdotto"  name="descrizioneProdotto" required minLength={4}/>
            </p>

            <p>
                <label htmlFor="prezzoProdotto">Prezzo</label>
                <input type="number" placeholder="Inserisci prezzo in €" id="prezzoProdotto" name="prezzoProdotto" required step="0.1" min='0.5' max='999.99' />
            </p>
            
            <p>
                <label htmlFor="immagineProdotto">Immagine (max: 150kb - 350x180px)</label>
                <input
                    type="file"                    
                    accept="image/jpeg, image/png"
                    id="image"
                    name="image"
                    onChange={(e) => handleFileChange(e)}
                />
                <input type="hidden" name="imageH" id="imageH" />
            </p>

            <p>
                <label htmlFor="categoriaProdotto">Scegli una categoria</label>
                <select name="categoriaProdotto" >
                    { categories.map((ele, idx) => <option  key={idx} value={ele.nome}>{ele.nome}</option>) }
                </select>
            </p>
            
        </div>

        <div className={classes.bodyPrd}>
            <h3>Ingredienti Aggiuntivi </h3>
            {
                row.map((ele, idx) => {
                    return (
                        <p key={idx}>
                            <label htmlFor={"agg" + idx}>Nome ingrediente</label>
                            <input id={"agg" + idx}  name={"agg" + idx} required minLength={4}/>
                            <label htmlFor={"aggcosto" + idx}>Costo</label>
                            <input id={"aggcosto" + idx}  name={"aggcosto" + idx} required type="number"/>
                        </p>
                    );
                })
            }

            <button onClick={(e) => {
                e.preventDefault();
                setRow(row => [...row, {}])
                setNumRow(numRow => numRow + 1)
            }}>
                + INGREDIENTI
            </button>
        </div>

        <input type="hidden" name="row" id="row" value={JSON.stringify(numRow)} />
        
        <button className={classes.btn} disabled={isSaving}>
            { isSaving ? 'SALVATAGGIO...' : 'SALVA'}
        </button>
    </Form>);
}