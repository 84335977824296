//******* THEME FOR MATERIAL UI  *******//

import { colors, createTheme } from "@mui/material";

const Theme = createTheme({
    palette: {
        background: {
            default: '#339DFF',
            primary: 'red',
            paper: '#80ffff',
        },
    },
    typography: {
        button: {
            fontSize: '1.0rem',
            fontFamily: 'Droid Serif',
        },
    },
})

export default Theme;
