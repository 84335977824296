import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import classes from './MiniTimeLine.module.css';
import { getFasceSettings, getOrariSettings, getSettings } from '../../helper/products';
import { acceptOrder } from '../../api/OrdersApi';
import { serviceActions } from '../../store/service';

function MiniTimeLine({id, hideModal, updateOrders}) {
    //STORE
    const timeLine = useSelector(state => state.service.timeLine);
    const dispatch = useDispatch()
    const [fasce, setFasce] = useState(15)
    const [driverAmt, setDriverAmt] = useState(1)
    const [orari, setOrari] = useState()
    const [tick, setTick] = useState()
    const [myTimer, setMyTimer] = useState()

    const placeOrder = async (driver, fascia, time) => {
        let finaltime = time + ((fasce * fascia) / 60)
        finaltime = parseFloat(convertToRealTime(finaltime)).toFixed(2)
        let newTime = JSON.parse(JSON.stringify(timeLine));
        newTime.driver[driver].time[fascia].orderN = id
        dispatch(serviceActions.updateTimeLine(newTime))
        await acceptOrder(id, finaltime, driver, fascia)
        hideModal()
        call()
        updateOrders()
    }

    //BASIC VAR FOR TIMELINE
    const rows = [];
    const today = new Date();
    const initToday = new Date().getDay();
    const dayOfWeek = initToday === 0 ? 6 : initToday - 1;

    const call = async () => {
        //GET INFO FROM SERVER
        const response = await getSettings()
        setDriverAmt(response.data.driverAmt)
        setFasce(response.data.fasce)
        setOrari(response.data.orari)

        //SOME STUFF TO CALC
        let myTimerz, endTimer
        const currentTime = today.getHours();
        if(currentTime >= 17) {
            myTimerz = parseFloat(response.data.orari[dayOfWeek].aperturaSera)
            endTimer = parseFloat(response.data.orari[dayOfWeek].chiusuraSera)
        } else {
            myTimerz = parseFloat(response.data.orari[dayOfWeek].aperturaMattina)
            endTimer = parseFloat(response.data.orari[dayOfWeek].chiusuraMattina)
        }

        let initT = convertToMinutes(myTimerz)
        let endT = convertToMinutes(endTimer)
        setMyTimer(myTimerz)
        let timePass = endT - initT
        if ( timePass < 0 )
            timePass = timePass + (24 * 60)

        const Tick = Math.floor(timePass / response.data.fasce);
        setTick(Tick)
    }

    const convertToMinutes = (time) => {
        const hours = Math.floor(time);
        const minutes = Math.floor((time - hours) * 60);
        return hours * 60 + minutes;
    };

    function convertToRealTime(decimalTime) {
        const hours = Math.floor(decimalTime);
        const minutes = Math.round((decimalTime % 1) * 60); 
      
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        return `${formattedHours}.${formattedMinutes}`;
    }

    useEffect(()=>{
        call();
    }, [])

    if (orari == null || timeLine == null)
        return;

    const headers = [];
    let min = 0, lastTimeInPast = -1, myTimerz = myTimer; //Serve a segnare quale header è l'ultimo non disponibile perchè è la colonna di un orario passato
    for (let j = 0; j < tick; j++) {
        const currentHourMin = today.getHours() + today.getMinutes() / 60;
        const currentHour = parseFloat(convertToRealTime(currentHourMin)); 
        const isPastTime = currentHour >= myTimerz;
        
        if (isPastTime)
            lastTimeInPast = j

        headers.push(<div className={classes.header} key={j}>
            Ora: {myTimerz.toFixed(2)}
        </div>); 
              
        myTimerz = myTimerz + (fasce / 100)

        min = myTimerz % 1;
        min = min * 100
        if (min > 59){
            myTimerz = myTimerz + 1
            myTimerz = myTimerz - 0.6
        }

        if (myTimerz > 24)
            myTimerz = myTimerz - 24
    }

    rows.push( <div className={classes.row} key="990">
        {headers}
    </div>);

    for (let i = 0; i < driverAmt; i++) { 
        const cells = [];
        let time = myTimer
        for (let j = 0; j < tick; j++) {
            //Mi calcolo che ore sono
            time = myTimer + ((fasce * j) / 60)
            time = parseFloat(convertToRealTime(time)).toFixed(2)
            if (j <= lastTimeInPast){
                cells.push(<div className={classes.past} key={j}>
                    ///
                </div>);
            } else {
                cells.push(<div className={classes.cell} key={j}>
                    {timeLine.driver[i].time[j].orderN != '' ? "///" : <button className={classes.btn} onClick={() => placeOrder(i,j,myTimer)}>Scegli</button> }
                </div>);
            }
        }

        rows.push( <div className={classes.row} key={i}>
            {cells}
        </div>);
        
    }

    return (<div className={classes.container}>
        <h2>IN QUALE ORARIO PUOI CONSEGNARE L'ORDINE?</h2>
        <div className={classes.table}>
            {rows}
        </div>   
    </div>);
}

export default MiniTimeLine;