import { redirect, useNavigate, json } from "react-router-dom";
import classes from './Login.module.css';

import LoginForm from "../component/auth/LoginForm";
import { callPost } from "../api/PuorkServer";

function Login() {
    const navigate = useNavigate(); 

    return (
        <div className={classes.slideup} >
            <LoginForm />

            <div style={{marginTop: 100, textAlign: "center"}}>
                <h1>Non hai ancora registrato la tua struttura?</h1>
                <button className={classes.btn} onClick={() => {navigate('/signup')}}>Registrala subito!</button>
            </div>
        </div>
    )
}

export async function action({request}){
    try {
        const data = await request.formData();
        
        const authData = { 
            mail : data.get('email'),
            password : data.get('password')
        }

        const response = await callPost('/company/signon', authData);

        if (response.status !== 200){
            return redirect('/errore?messaggio=' + response.data.message);  
        }

        const token = response.data.token;
        const isFirstTime = response.data.isFT;
        localStorage.setItem('token', token)

        if (isFirstTime)
            return redirect('/setup');
        else
            return redirect('/');
    } catch (e) {      
        
    }
}

export default Login;

