import { redirect } from "react-router-dom";

export function getAuthToken() {
    const token = localStorage.getItem('token');
    return token;
}

export function logoutAction(){
    localStorage.removeItem('token')
    return redirect("/")
}

export function tokenLoader(){
    return getAuthToken();
}

export function CheckAuth(){
    const token = getAuthToken();
    
    if (!token){
        return redirect("/guest");
    }

    return null;
}