import { NavLink, Form, useRouteLoaderData } from "react-router-dom";

import StoreIcon from '@mui/icons-material/Store';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import CategoryIcon from '@mui/icons-material/Category';
import MenuBookIcon from '@mui/icons-material/MenuBook';

import classes from './SubTopBarNavigation.module.css';

function SubTopBarNavigation() {
    return (
        <header className={classes.header}>
            <nav className={classes.nav}>
                <ul className={classes.list}>
                    <li className={classes.ele}>
                        <StoreIcon /> <NavLink to="/settings/company" className={({isActive}) => isActive ? classes.active : undefined}>Gestione Azienda</NavLink>          
                    </li>
                    <li className={classes.ele}>
                        <DinnerDiningIcon /> <NavLink to="/settings/servizio" className={({isActive}) => isActive ? classes.active : undefined}>Gestione Servizio</NavLink>          
                    </li>
                    <li className={classes.ele}>
                        <CategoryIcon />  <NavLink to="/settings/categories" className={({isActive}) => isActive ? classes.active : undefined}>Gestione Categorie</NavLink>
                    </li>
                    <li className={classes.ele}>
                        <MenuBookIcon />  <NavLink to="/settings/products" className={({isActive}) => isActive ? classes.active : undefined}>Gestione Prodotti</NavLink>
                    </li>
                    {/*<li className={classes.ele}>
                        <NavLink to="/settings/menu" className={({isActive}) => isActive ? classes.active : undefined}>Gestione Menu</NavLink>
                    </li>*/}
                </ul>
            </nav>
        </header>
    )
}

export default SubTopBarNavigation;