// --- Here we go! --- \\
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {createBrowserRouter,RouterProvider} from "react-router-dom";
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';

//Pages Import
import HomePage from './pages/Homepage';
import Analytics from './pages/Analytics';
import Login, {action as authAction} from './pages/Login';
import Settings from './pages/Settings';
import Help, {action as helpAction} from './pages/Help';
import ManageProductList from './pages/settings/ManageProductList';
import ManageCompany from './pages/settings/ManageCompany';
import Servizio from './component/settings/Servizio';
import ModifyProduct from './pages/settings/ModifyProduct';
import ErrorPage from './pages/Error';
import AddProduct from './pages/settings/AddProduct';
import AddCategory from './pages/settings/AddCategory';
import ManageCategoryList from './pages/settings/ManageCategory';
import Register, {action as registerAction} from './pages/Register';
import Setup from './pages/Setup';
import Guest from './pages/Guest';
import Plans from './pages/Plans';
//Roots Import
import RootLayout from './helper/Root';
import SubRootLayout from './helper/SubRoot';

//Actions Import
import {action as AddPrdAction} from './pages/settings/AddProduct';
import {action as AddCatAction} from './pages/settings/AddCategory';
import {action as CompanyAction} from './component/settings/Company';
import {action as ServizioAction} from './component/settings/Servizio'

//Other Import
import { logoutAction, CheckAuth } from './helper/auth';
import { getProductsList, getCategoryList, getInfo, getSettings } from './helper/products';
import store from "./store/main";
import ManageMenu from './pages/settings/ManageMenu';
import VersionInfo from './component/VersionInfo';
import UserVerify from './pages/external/UserVerify';
import { ThemeProvider } from '@mui/material';
import Theme from './ui/Theme';

const root = ReactDOM.createRoot(document.getElementById('root'));
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />, 
      errorElement: <ErrorPage />,
      id: 'root',
      loader: CheckAuth,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/error",
        element: <ErrorPage />,
      },
      {
        path: "/analytics",
        element: <Analytics />, 
      },
      {
        path: "/settings",
        element: <SubRootLayout />,
        children: [
          {
            path: "",
            element: <Settings />
          },
          {
            path: "company",
            element: <ManageCompany />,
            action: CompanyAction,
            loader: getInfo
          },
          {
            path: "servizio",
            element: <Servizio />,
            loader: getSettings,
            action: ServizioAction
          },
          {
            path: "products",
            element: <ManageProductList />,
            loader: getProductsList
          },
          {
            path: "products/add",
            element: <AddProduct />,
            loader: getCategoryList,
            action: AddPrdAction
          },
          {
            path: "products/:id",
            element: <ModifyProduct />, 
          },
          {
            path: "categories",
            element: <ManageCategoryList />,
            loader: getCategoryList
          },
          {
            path: "categories/add",
            element: <AddCategory />,
            action: AddCatAction
          },
          {
            path: "menu",
            element: <ManageMenu />
          },
        ]
      },
      {
        path: "help",
        element: <Help />,
        action: helpAction
      }
    ]
  },
  {
    path: "/guest",
    element: <Guest />
  },
  {
    path: "/plans",
    element: <Plans />
  },
  {
    path: "/sign",
    element: <Login />, 
    errorElement: <ErrorPage />,
    action: authAction
  },
  {
    path: "/signup",
    element: <Register />, 
    errorElement: <ErrorPage />,
    action: registerAction
  },
  {
    path: "logout",
    action: logoutAction
  },
  {
    path: "/setup",
    element: <Setup />, 
    errorElement: <ErrorPage />
  },
  {
    path: "/verify/user",
    element: <UserVerify />
  }
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={Theme}>
          <RouterProvider router={router} />        
          <VersionInfo />

      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);