import {callPost} from "../../api/PuorkServer";
import { redirect } from "react-router-dom";
import FormAddCategory from "../../component/settings/FormAddCategory";

function AddCategory() {
    return <FormAddCategory />
}

export async function action({request}) {
    const formData = await request.formData();
    const data = {
        nome: formData.get('nome'),
    };

    const response = await callPost('/company/category/add', data);
    return redirect("/settings/categories");
}

export default AddCategory;