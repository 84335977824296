import { useState } from 'react';

import classes from './Servizio.module.css';

const Spedizione = () => {
    const [free, setFree] = useState(false);
    const [fixed, setFixed] = useState(false);
    const [variable, setVariable] = useState(false);
    const [fixedCost, setFixedCost] = useState('');
    const [variablelimit1, setVariablelimit1] = useState('');
    const [variablelimit2, setVariablelimit2] = useState('');
    const [variableCost1, setVariableCost1] = useState('');
    const [variableCost2, setVariableCost2] = useState('');
    const [variableLimitFree, setVariableLimitFree] = useState('');

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        if (name === 'free') {
            setFree(checked);
            setFixed(false);
            setVariable(false);
        } else if (name === 'fixed') {
            setFree(false);
            setFixed(checked);
            setVariable(false);
        } else if ( name === 'variable') {
            setFree(false);
            setFixed(false);
            setVariable(checked);
        }
    };

    return (
        <div className={classes.box}>
            <h1>Costi consegne</h1>
            <p>
                Scegli come gestire i costi delle spedizioni dei tuoi ordini.
            </p>
            <label>
                <input
                    type="checkbox"
                    name="free"
                    checked={free}
                    onChange={handleCheckboxChange}
                />
                Spedizione gratis
            </label>
            <br />
            <label>
                <input
                type="checkbox"
                name="fixed"
                checked={fixed}
                onChange={handleCheckboxChange}
                />
                Costo fisso
            </label>
            <br />
            <label>
                <input
                type="checkbox"
                name="variable"
                checked={variable}
                onChange={handleCheckboxChange}
                />
                Costo Variabile
            </label>
            <br />
      
            {   fixed && (
                <input
                    title="Ogni ordine avrà lo stesso importo per la spedizione (costo minimo di 1€ massimo di 10€)"
                    type="number"
                    placeholder="Inserisci il costo di spedizione fisso"
                    value={fixedCost}
                    name="fixedCost"
                    min={1}
                    max={10}
                    onChange={(e) => setFixedCost(e.target.value)}
                />
            )}

            { variable && (
                <div>
                    <h4>In base al totale dell'ordine l'utente avrà un costo dinamico di spedizione: </h4>
                    <p>
                        <ul>
                            <li>Fino a "Primo Limite" avrà "Primo Prezzo" come costo.</li>
                            <li>Da "Primo Limite" a "Secondo Limite" avrà "Secondo Prezzo" come costo</li>
                            <li>Con ordini maggiore di "Limite Gratis" il cliente potrà avere la spedizione gratis</li>
                        </ul>
                        <b>Nota Bene:</b> Il primo limite deve essere inferiore al secondo ed il terzo, inoltre il secondo limite deve essere inferiore o uguale al terzo. Infine se desideri non applicare una spedizione gratuita ti basterà semplicemente inserire "9999" nel "Limite Gratis"
                    </p>
                    <input
                        type="number"
                        title="Somma di totale nella quale rientra la prima fascia di prezzo"
                        placeholder="Primo Limite"
                        value={variablelimit1}
                        name="firstLimit"
                        onChange={(e) => setVariablelimit1(e.target.value)}
                    />
                    <input
                        type="number"
                        placeholder="Primo Prezzo"
                        value={variableCost1}
                        name="firstCost"
                        onChange={(e) => setVariableCost1(e.target.value)}
                    />
                    <br />
                    <input
                        type="number"
                        title="Somma di totale nella quale rientra la seconda fascia di prezzo"
                        placeholder="Secondo Limite"
                        value={variablelimit2}
                        name="secondLimit"
                        onChange={(e) => setVariablelimit2(e.target.value)}
                    />
                    <input
                        type="number"
                        placeholder="Secondo Prezzo"
                        value={variableCost2}
                        name="secondCost"
                        onChange={(e) => setVariableCost2(e.target.value)}
                    />
                    <br />
                    <input
                        type="number"
                        title="Somma di totale da superare per un ordine gratis"
                        placeholder="Limite Gratis"
                        value={variableLimitFree}
                        name="freeLimit"
                        onChange={(e) => setVariableLimitFree(e.target.value)}
                    />
                </div>
            )}
        </div>
    );
};

export default Spedizione;
