function ManageMenu() {
    return (
        <div>
            <h3>Funzionalità in arrivo!</h3>
            <p>
                Questa è funzionalità ancora non è disponibile ma presto ti permetterà di creare un menu inteso come "bundle" di prodotti insieme, utilizzalo per creare offerte ed attirare nuovi clienti!
            </p>
        </div>
    );
}

export default ManageMenu;