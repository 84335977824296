import { Fragment } from 'react';
import ReactDom from 'react-dom';
import classes from './Modal.module.css';

const portalElement = document.getElementById('overlays');

const Backdrop = props => {
    return <div className={classes.backdrop} onClick={props.onClose} />
}

const ModalOverlay = props => {
    return <div className={classes.modal}>
        <div className={classes.content} >
            {props.children}
        </div>
    </div>
}

const SubModalOverlay = props => {
    return <div className={classes.submodal}>
        <div className={classes.content} >
            {props.children}
        </div>
    </div>
}

const Modal = props => {
    return <Fragment>
        {ReactDom.createPortal(<Backdrop onClose={props.onClose} />, portalElement)}
        {ReactDom.createPortal(<ModalOverlay>{props.children}</ModalOverlay>, portalElement)}
    </Fragment>
}

export default Modal;

export const SubModal = props => {
    return <Fragment>
        {ReactDom.createPortal(<Backdrop onClose={props.onClose} />, portalElement)}
        {ReactDom.createPortal(<SubModalOverlay>{props.children}</SubModalOverlay>, portalElement)}
    </Fragment>
}