import classes from './ListElementKitchen.module.css';

function KitchenElement({items, id, orario}) {

    return  <div className={classes.row}>    
        <div className={classes.cell} style={{ width: '8%' }} > {id}</div>
        <div className={classes.cell} style={{ width: '15%' }} > {items.nome}</div>
        <div className={classes.cell} style={{ width: '25%' }} > {items.descrizione}</div>
        <div className={classes.cell} style={{ width: '15%' }} > {items.aggiuntivi.map(ele => ele.nome + " ")}</div>
        <div className={classes.cell} style={{ width: '8%' }} > {orario.toFixed(2)}</div>
    </div>
    
}

export function KitchenElementTemplate(){
    return  <div className={classes.row}>
        <div className={classes.header} style={{ width: '8%' }}> N° ORDINE </div>
        <div className={classes.header} style={{ width: '15%' }}> NOME PIETANZA </div>
        <div className={classes.header} style={{ width: '25%' }}> DESCRIZIONE </div>
        <div className={classes.header} style={{ width: '15%' }}> INGREDIENTI AGGIUNTIVI </div>
        <div className={classes.header} style={{ width: '8%' }}> ORARIO </div>
    </div>
}

export default KitchenElement;