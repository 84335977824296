import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import classes from './TimeLine.module.css';
import { getFasceSettings, getOrariSettings, getSettings } from '../../helper/products';
import { serviceActions } from '../../store/service';
import { OrderProductSelfDetail } from "./modal/OrderProductsDetail";
import { getOrders } from "../../api/OrdersApi";

function TimeLine() {
    //STORE
    const dispatch = useDispatch();
    const timeLine = useSelector(state => state.service.timeLine);

    const [fasce, setFasce] = useState(15)
    const [driverAmt, setDriverAmt] = useState(1)
    const [orari, setOrari] = useState()
    const [tick, setTick] = useState()
    const [myTimer, setMyTimer] = useState()
    const [today, setToday] = useState(new Date())
    const [orders, setOrders] = useState([]);
    const [isMattina, setIsMattina] = useState(false);
    
    const [showDetail, setShowDetail] = useState(false)
    const [orderNSelected, setOrderNSelected] = useState();
    const setModal = (orderN, val) => {
        setOrderNSelected(orderN); 
        setShowDetail(val)
    }

    //BASIC VAR FOR TIMELINE
    const rows = [];
    //const today = new Date();
    const initToday = new Date().getDay();
    const dayOfWeek = initToday === 0 ? 6 : initToday - 1;

    //METHOD FOR INITIALIZE TIMELINE STORE
    const initTimeLine = (driver,fasce, ord) => {
        const newTimeLine = {
            driver: [],
        };

        for (let i = 0; i < driver; i++) {            
            const newDriver = {
              time: [],
            };

            for (let j = 0; j < fasce; j++) {
                let found = false
                if (ord != null && ord.lenght > 0) {
                    ord.forEach(ele => {
                        if (ele.time.companyRes.driver == i && ele.time.companyRes.fascia == j){
                            const newTime = {orderN: ele._id};                      
                            newDriver.time.push(newTime);
                            found = true
                        }
                    })
                }
                if (!found) {
                    const newTime = {orderN: ""};                      
                    newDriver.time.push(newTime);
                }
                found = false
            }
            newTimeLine.driver.push(newDriver);
        }

        return newTimeLine
    }

    //STUFF FOR MODAL INFO
    const showProductsDetailModal = () => {
        setShowDetail(true)
    }
    const hideProductsDetailModal = () => {
        setShowDetail(false)
    }

    const call = async () => {
        //GET INFO FROM SERVER
        const response = await getSettings();
        setDriverAmt(response.data.driverAmt)
        setFasce(response.data.fasce)
        setOrari(response.data.orari)
        //Orders
        const responseOrders = await getOrders(1);
        setOrders(responseOrders.data)       

        //const time = 9.45;
        //const { hours, minutes } = extractTime(time);

        //SOME STUFF TO CALC
        let myTimerz, endTimer
        const currentTime = today.getHours();
        if(currentTime >= 17) {
            setIsMattina(false)
            myTimerz = parseFloat(response.data.orari[dayOfWeek].aperturaSera)
            endTimer = parseFloat(response.data.orari[dayOfWeek].chiusuraSera)
        } else {
            setIsMattina(true)
            myTimerz = parseFloat(response.data.orari[dayOfWeek].aperturaMattina)
            endTimer = parseFloat(response.data.orari[dayOfWeek].chiusuraMattina)
        }

        let initT = convertToMinutes(myTimerz)
        let endT = convertToMinutes(endTimer)
        setMyTimer(myTimerz)
        let timePass = endT - initT
        if ( timePass < 0 )
            timePass = timePass + (24 * 60)

        //Tick = Quante consegne si potranno effettuare nel servizio
        const Tick = Math.floor(timePass / response.data.fasce);
        setTick(Tick)

        //SET TIMELINE STORE WITH BLANK VALUE
        if (timeLine == null) {
            const initTimeline = initTimeLine(response.data.driverAmt, Tick, responseOrders.data)
            dispatch(serviceActions.updateTimeLine(initTimeline))
        }
    }

    const convertToMinutes = (time) => {
        const hours = Math.floor(time);
        const minutes = Math.floor((time - hours) * 60);
        return hours * 60 + minutes;
    }; 

    function formatted() {
        const hoursInt = parseInt(today.getHours(), 10);
        const minutesInt = parseInt(today.getMinutes(), 10);
        return `${hoursInt}.${minutesInt}`;
    }

    useEffect(()=>{
        call();
        const t = setInterval(() => {
            setToday(new Date());
        }, 300000); //5 min
      
        return () => {
            clearInterval(t);
        };
    }, [])

    if (orari == null || timeLine == null)
        return;

    const headers = [];
    let min = 0, lastTimeInPast = -1, myTimerz = myTimer; //Serve a segnare quale header è l'ultimo non disponibile perchè è la colonna di un orario passato
    for (let j = 0; j < tick; j++) {
        const currentHour = parseFloat(formatted()); 
        const isPastTime = currentHour >= myTimerz;
        
        if (isPastTime)
            lastTimeInPast = j

        headers.push(<div className={classes.header} key={j}>
            Ora: {myTimerz.toFixed(2)}
        </div>);        
              
        myTimerz = myTimerz + (fasce / 100)

        min = myTimerz % 1;
        min = min * 100
        if (min > 59){
            myTimerz = myTimerz + 1
            myTimerz = myTimerz - 0.6
        }

        if (myTimerz > 24)
            myTimerz = myTimerz - 24
    }

    rows.push( <div className={classes.row} key="990">
        {headers}
    </div>);

    for (let i = 0; i < driverAmt; i++) { 
        const cells = [];
        for (let j = 0; j < tick; j++) {
            if (j <= lastTimeInPast){
                cells.push(<div className={classes.past} key={j}>
                    {timeLine.driver[i].time[j].orderN == '' ? "///" : <button onClick={() => setModal(timeLine.driver[i].time[j].orderN, true)}>VISUALIZZA ORDINE</button> }
                </div>);
            } else {
                cells.push(<div className={classes.cell} key={j}>
                    {timeLine.driver[i].time[j].orderN == '' ? "LIBERO" : <button onClick={() => setModal(timeLine.driver[i].time[j].orderN, true)}>VISUALIZZA ORDINE</button> }
                </div>);
            }
        }

        rows.push( <div className={classes.row} key={i}>
            {cells}
        </div>);
        
    }

    return (<div className={classes.container}>
            <span className={classes.title}>Timeline Ordinazioni {isMattina ? "(Turno Mattina)" : "(Turno Sera)"}</span>
        <div className={classes.table}>
            {rows}
        </div>  
        
        {showDetail && <OrderProductSelfDetail hideModal={hideProductsDetailModal} orderN={orderNSelected} />}
    </div>);
}

export default TimeLine;