import React, { useState } from 'react';
import Modal from '../../../ui/Modal';
import classes from './OrderAssistance.module.css';

const OrderAssistance = ({hideModal, handleContatta, handleContesta}) => {
    return (
        <Modal onClose={hideModal} className={classes.modal}>
            <div className={classes.modal}>
                <h3>Assistenza</h3>
                <button className={classes.btn} onClick={handleContesta}>Contesta l'ordine</button>
                <button className={classes.btn} onClick={handleContatta}>Contatta un operatore</button>
            </div>
        </Modal>
    );
};

export default OrderAssistance;