import { NavLink, Form } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { serviceActions } from '../../store/service';

import SettingsIcon from '@mui/icons-material/Settings';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import QuizIcon from '@mui/icons-material/Quiz';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import classes from './TopBarNavigation.module.css';

function TopBarNavigation() {
    const dispatch = useDispatch();
    const isInService = useSelector(state => state.service.isInService);

    const handleClick = (e) => {  
        if (isInService)      
            if(!window.confirm('Cambiando navigazione uscirai dal servizio ed i tuoi clienti non potranno ordinare, inoltre tutti gli ordini in attesa saranno annullati! Vuoi uscire dal servizio?'))
                e.preventDefault()
            else
                dispatch(serviceActions.serviceOff())
    }

    return (
        <div className={classes.header}>   
            <div className={classes.logocontainer}> 
                <img src="logo_head.png" className={classes.logo} />
            </div>             
            <nav className={classes.nav}>
                <ul className={classes.list}>
                    <li className={classes.ele}>
                        <RestaurantMenuIcon /> <NavLink to="/" onClick={handleClick} className={({isActive}) => isActive ? classes.active : undefined}>Servizio</NavLink>          
                    </li>
                    { /*<li className={classes.ele}>
                        <NavLink to="/analytics" onClick={handleClick} className={({isActive}) => isActive ? classes.active : undefined}>Analisi e Statistiche</NavLink>
                    </li>
                    */}
                    <li className={classes.ele}>
                        <SettingsIcon /> <NavLink to="/settings/company" onClick={handleClick} className={({isActive}) => isActive ? classes.active : undefined}>Impostazioni</NavLink>
                    </li>
                    <li className={classes.ele}>
                        <QuizIcon /> <NavLink to="/help" onClick={handleClick} className={({isActive}) => isActive ? classes.active : undefined}>Supporto</NavLink>  
                    </li>
                    <li className={classes.ele}>
                        <Form action="/logout" method="POST">
                            <ExitToAppIcon /> <button className={classes.btn} onClick={handleClick} >Logout</button>  
                        </Form>
                    </li>
                </ul>
            </nav>            
        </div>
    )
}

export default TopBarNavigation;