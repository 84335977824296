import { Form, useActionData } from 'react-router-dom';
import classes from './LoginForm.module.css';

function LoginForm() {
    const data = useActionData();

    return (
        <>
            <Form method="post" className={classes.form}>
                <h1>Log in</h1>
                {data && data.error && <h3>{data.error}</h3>}
                {data && data.message && <p>{data.message}</p>}
                <p>
                    <label htmlFor="email">Email</label>
                    <input id="email" type="email" name="email" required />
                </p>
                <p>
                    <label htmlFor="password">Password</label>
                    <input id="password" type="password" name="password" required />
                </p>
                <div className={classes.actions}>
                    <button className={classes.btn}>Accedi</button>
                </div>
            </Form>
        </>
    );
}

export default LoginForm;