import Dialog from '@mui/material/Dialog';

import classes from './PlanCards.module.css';
import { useState } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { sendMail } from '../../api/CompanyApi';



/* ===== */
const PlanDialog = ({plan, DialogClose}) => {
    const [open, setOpen] = useState(true)

    const handleClose = () => {
        setOpen(false);
        DialogClose()
    };

    return <div className={classes.container}>
        <Dialog
            open={open}
            onClose={handleClose}
            disableEscapeKeyDown
            PaperProps={{
                component: 'form',
                onSubmit: async (event) => {
                  event.preventDefault();
                  const formData = new FormData(event.currentTarget);
                  const formJson = Object.fromEntries(formData.entries());
                  const email = formJson.mail;
                  const num = formJson.cell;
                  const title = "Richiesta iscrizione [" + plan + "]";
                  const messaggio = "Un utente ha richiesto il plan '" + plan + "' con la seguente mail [" + email + "] e seg. numero: [" + num + "]";
                  const serverCall = await sendMail(email, title, messaggio)
                  console.log(serverCall.data.message)
                },
            }}
        >
            <DialogTitle>Inviazione</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Desideri sottoscrivere il piano: <b>"{plan}" </b>. <b>A breve ti contatterà il supporto</b> per ulteriori informazioni e per procedere al pagamento ed all'upgrade dell'account, <b>ricontrolla i seguenti dati di contatto</b>:
                </DialogContentText>
                <TextField autoFocus
                    required
                    margin="dense"
                    id="mail"
                    name="mail"
                    label="Mail di contatto"
                    type="email"
                    fullWidth
                    variant="standard"
                />
                <TextField 
                    required
                    margin="dense"
                    id="cell"
                    name="cell"
                    label="Numero telefonico"
                    type="number"
                    fullWidth
                    variant="standard"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} >Cancella</Button>
                <Button type='submit'>Invia Richiesta</Button>
                <Button type="submit">Subscribe</Button>
            </DialogActions>

        </Dialog>
    </div>;
}
  
  export default PlanDialog;
  