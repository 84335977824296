import React, { useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { deleteProduct, modifyProduct, deleteProductAgg, modifyProductAgg } from '../../api/CompanyApi';
import LoadingScreen from '../navigation/LoadingScreen';
import { MRT_Localization_IT } from 'material-react-table/locales/it';

function Prodotti ({data, categories}) {   
    const [isLoading, setIsLoading] = useState(false)

    const handleDelete = async (row) => {
        if (window.confirm('Sei sicuro di voler eliminare questo prodotto?')){
            setIsLoading(true)
            const response = await deleteProduct(data[row.id]._id);
            setIsLoading(false)
            if (response.status == 200) {
                let newUrl = "/settings/products?status=success&message=Articolo Eliminato correttamente";
                window.location.href = newUrl;
            } else {
                let newUrl = "/settings/products?status=error&message=" + response.data.message;
                window.location.href = newUrl;
            }
        }
        else
          return;
    };

    const handleDeleteSub = async (row) => {
        if (window.confirm('Sei sicuro di voler eliminare questo prodotto?')) {
            setIsLoading(true)
            const response = await deleteProductAgg(row.original._id, row.original.nome, row.original.costo)
            setIsLoading(false)
            if (response.status == 200) {
                let newUrl = "/settings/products?status=success&message=Articolo Eliminato correttamente";
                window.location.href = newUrl;
            } else {
                let newUrl = "/settings/products?status=error&message=" + response.data.message;
                window.location.href = newUrl;
            }
        }
        else
            return;
    };

    const handleEdit = async ({ exitEditingMode, row, values }) => {
        setIsLoading(true)
        await modifyProduct(data[row.id]._id, values.nome, values.descrizione, values.prezzo, values.categoria)
        exitEditingMode()
        setIsLoading(false)
    };

    const handleEditSub = async ({ exitEditingMode, row, values }) => {
        setIsLoading(true)
        await modifyProductAgg(row.original._id, values.nome, values.costo, row.original.nome, row.original.costo)
        exitEditingMode()
        setIsLoading(false)
  };

    const columns = useMemo(() => [
        {
            header: 'Nome',
            accessorKey: 'nome', 
            size: 300
        },
        {
            header: 'Descrizione',
            accessorKey: 'descrizione', 
        },
        {
            header: 'Prezzo',
            accessorKey: 'prezzo', 
        },
        {
            header: 'Categoria',
            accessorKey: 'categoria', 
            editVariant: 'select',
            editSelectOptions: categories.map(category => category.nome),
        }
    ],[]);

    const subColumns = useMemo(() => [
        {
            header: 'Nome',
            accessorKey: 'nome',
            size: 300
        },
        {
            header: 'Prezzo aggiuntivo',
            accessorKey: 'costo', 
        },
    ],[],);

    return (
        <div>
            {isLoading ? <LoadingScreen /> : null}
            <MaterialReactTable
                columns={columns}
                data={data}        
                enableEditing
                onEditingRowSave={handleEdit}
                enableColumnOrdering
                enableGlobalFilter={false}
                localization={MRT_Localization_IT}
                renderRowActionMenuItems={({ row }) => [
                  <div onClick={() => handleDelete(row)}>  
                     Elimina
                  </div>
                ]}
                positionActionsColumn="last"
                renderDetailPanel={({ row }) => (
                    <MaterialReactTable
                        columns={subColumns}
                        data={row.original.ingredientiAgg}
                        enableGlobalFilter={false}      
                        enableEditing
                        onEditingRowSave={handleEditSub}
                        edit
                        renderRowActionMenuItems={({ row }) => [
                          <div onClick={() => handleDeleteSub(row)}>  
                            Elimina
                          </div>,
                        ]}
                        localization={MRT_Localization_IT}
                        //Minimal:                        
                        enableColumnFilters={false}
                        enablePagination={false}
                        enableSorting={false}
                        enableBottomToolbar={false}       
                        enableTopToolbar={false}      
                    />
                )}
            />
        </div>
    );
}
  
export default Prodotti;